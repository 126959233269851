import { Button, Row, Col } from "antd";
import styled from "styled-components";

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 586px;
`;

const PrimaryButton = styled(Button)`
  border-radius: 2px;
  padding: 6px 16px;
  font-size: 13px;
  background: var(--ck2);
  border-color: var(--ck2);
  text-shadow: none;
`;

const SecondaryButton = styled(Button)`
  border-radius: 4px;
  padding: 5px 16px;
  font-size: 13px;
  border-color: var(--gray-400);
  text-shadow: none;
  color: var(--gray-900);
`;

export function ModalFooter({ children }) {
  return (
    <Row justify="center">
      <FooterWrapper>{children}</FooterWrapper>
    </Row>
  );
}

export function PaginatedModalFooter({
  onSubmit,
  canSubmit,
  onPrevious,
  onNext,
  onSkip,
  page = 1,
  pageCount = 1,
  disabled,
  submitting,
  submitLabel = "Create",
  prevLabel = "Previous",
  nextLabel = "Next",
  skipLabel = "Skip",
  skippablePages = [],
}) {
  const Previous = (
    <SecondaryButton
      type="default"
      onClick={onPrevious}
      disabled={disabled}
      data-testid="modal-footer-prev-button"
    >
      {prevLabel}
    </SecondaryButton>
  );

  const Skip = (
    <SecondaryButton
      type="default"
      onClick={onSkip}
      disabled={disabled}
      loading={submitting}
      style={{ marginRight: 8 }}
      data-testid="modal-footer-skip-button"
    >
      {skipLabel}
    </SecondaryButton>
  );

  const Next = (
    <PrimaryButton
      type="primary"
      onClick={onNext}
      disabled={disabled}
      loading={submitting}
      data-testid="modal-footer-next-button"
    >
      {nextLabel}
    </PrimaryButton>
  );

  const Empty = <div />;

  switch (page) {
    case pageCount:
      return (
        <ModalFooter>
          {pageCount === 1 ? Empty : Previous}
          <PrimaryButton
            type="primary"
            onClick={onSubmit}
            disabled={disabled || !canSubmit}
            loading={submitting}
            data-testid="modal-footer-submit-button"
          >
            {submitLabel}
          </PrimaryButton>
        </ModalFooter>
      );
    case 1:
      return (
        <ModalFooter>
          {Empty}
          {Next}
        </ModalFooter>
      );
    default:
      return (
        <ModalFooter>
          {Previous}
          <Col>
            {skippablePages.includes(page) && Skip}
            {Next}
          </Col>
        </ModalFooter>
      );
  }
}
