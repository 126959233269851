export function VerticalSeperator({ margin, width, color, height }) {
  return (
    <div
      style={{
        height,
        marginLeft: margin,
        marginRight: margin,
        width,
        backgroundColor: color,
      }}
    />
  );
}
