// Helper functions for temporary implementation of user groups - As of 15 Aug 2022.
// Until a more robust solution is being implemented, we want to group all existing (temporary) helper functions here
// so as to easily locate these functions in future to remove / refactor from files utilizing them.

import {
  assigneeEmailMapToGroup,
  assigneeGroupAvatarUrl,
  assigneeGroupLabelMap,
  assigneeGroupMapToEmail,
} from "../../const";
import { getCollaboratorsForMention } from "../frontend/fetchFromApi";

export function replaceAssigneeGroups(emailList, collaboratorsList) {
  for (const assigneeGroupEmail in assigneeEmailMapToGroup) {
    if (emailList.includes(assigneeGroupEmail)) {
      // Get emails of all collaborators with the matched role
      const allRoleCollaboratorIds = collaboratorsList
        .filter((collaborator) => collaborator.role === assigneeEmailMapToGroup[assigneeGroupEmail])
        .map((collaborator) => collaborator.userId);
      emailList = [...emailList, ...allRoleCollaboratorIds];
    }
  }
  // Remove the assigneeGroup email and remove duplicates
  return [...new Set(emailList.filter((email) => !(email in assigneeEmailMapToGroup)))];
}

// For comments, need to (re)fetch full, non-modified collaborator list
export async function replaceAssigneeGroupsForComment(
  emailList,
  projectId,
  projectTenantId,
  userId,
  authData
) {
  // Fetch pure, non-modified collaborator list for the given project
  const collaboratorsList = await getCollaboratorsForMention(
    projectId,
    projectTenantId,
    userId,
    authData,
    false
  );

  return replaceAssigneeGroups(emailList, collaboratorsList);
}

export function removeAssigneeGroupsEmails(emailList, removedGroupEmailList, collaboratorsList) {
  for (const removedGroupEmail of removedGroupEmailList) {
    if (Object.keys(assigneeEmailMapToGroup).includes(removedGroupEmail)) {
      // Get emails of all collaborators with the matched role
      const allRoleCollaboratorIds = collaboratorsList
        .filter((collaborator) => collaborator.role === assigneeEmailMapToGroup[removedGroupEmail])
        .map((collaborator) => collaborator.userId);

      emailList = emailList.filter((email) => !allRoleCollaboratorIds.includes(email));
    }
  }
  return emailList;
}

export function getGroupsToInsertAndReformattedCollabList(collaboratorsList) {
  const groupsToInsert = [];
  Object.keys(assigneeGroupMapToEmail).map((assigneeGroupRole) => {
    if (
      collaboratorsList.some(
        (collaborator) => collaborator.role === assigneeGroupRole && !collaborator.isPending
      )
    ) {
      collaboratorsList = collaboratorsList.filter(
        (collaborator) => collaborator.role !== assigneeGroupRole
      );
      groupsToInsert.push({
        id: assigneeGroupMapToEmail[assigneeGroupRole],
        value: assigneeGroupLabelMap[assigneeGroupRole],
        ...(assigneeGroupRole === "certikSupport" ? { status: "online" } : {}),
      });
    }
  });
  return { groupsToInsert, reformattedCollaboratorList: collaboratorsList };
}

export function updateCollaboratorListForMentionWithGroups(collaboratorsList, groupsToInsert) {
  if (groupsToInsert.length > 0) {
    collaboratorsList.unshift(...groupsToInsert);
  }
  return collaboratorsList;
}

export function modifyAssigneeSectionLists(roles, candidateList, curUserList) {
  for (const role of roles) {
    if (role in assigneeGroupMapToEmail) {
      if (
        // All possible role collaborators added, so add the option of an assignee group to the cur list so that it will be registered as "selected" in the dropdown
        candidateList
          .map((userInfo) => userInfo.userId)
          .every((userId) => curUserList.includes(userId))
      ) {
        curUserList.unshift(assigneeGroupMapToEmail[role]); // skyharbor-support@certik.com"
      }

      // Add to dropdown list
      candidateList.unshift({
        userId: assigneeGroupMapToEmail[role], // "skyharbor-support@certik.com"
        userName: assigneeGroupLabelMap[role], // "Certik Support (All Certik Support users)"
        role: role, // "certikSupport"
        avatarUrl: assigneeGroupAvatarUrl[role], // display picture
      });
    }
  }
  return { modifiedCurUserList: curUserList, modifiedCandidateList: candidateList };
}

// Not that great way of matching
export function replaceCommentItemWithGroupEmailIfExists(commentItem) {
  if (commentItem?.role in assigneeGroupMapToEmail) {
    return {
      ...commentItem,
      userId: assigneeGroupMapToEmail[commentItem.role],
      userName: assigneeGroupLabelMap[commentItem.role],
      avatarUrl: assigneeGroupAvatarUrl[commentItem.role],
    };
  } else {
    return commentItem;
  }
}

export function getUserIdAccountingForUserGroups(userInfo) {
  if (userInfo && userInfo?.role) {
    if (userInfo.role in assigneeGroupMapToEmail) {
      return assigneeGroupMapToEmail[userInfo.role];
    }
  }
  return userInfo?.id || userInfo?.userId || "";
}

export function getUserNameAccountingForUserGroups(userInfo) {
  if (userInfo && userInfo?.role) {
    if (userInfo.role in assigneeGroupMapToEmail) {
      return assigneeGroupLabelMap[userInfo.role];
    }
  }
  return userInfo?.userName || "";
}
