import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { COLORS } from "../../const";

export function TableSearchInputWidth(searchQuery, maxWidth) {
  let width = 280;
  if (searchQuery?.length && searchQuery?.length > 20) {
    if (searchQuery.length < 30) {
      width = 14 * 30;
    } else {
      width = 600;
    }
  }
  return maxWidth != undefined ? Math.min(maxWidth, width) : width;
}

export function TableSearch({
  searchQuery,
  setSearchQuery,
  placeholder,
  iconSuffix = false,
  onPressEnter,
  maxWidth,
  width,
  dataTestId = null,
}) {
  let dynamicWidth = TableSearchInputWidth(searchQuery, maxWidth);

  return (
    <Input
      data-testid={dataTestId || "table-search"}
      style={{ fontWeight: 300, maxWidth: width || dynamicWidth }}
      placeholder={placeholder}
      allowClear={true}
      value={searchQuery}
      suffix={iconSuffix && <SearchOutlined style={{ color: COLORS["gray-6"] }} />}
      prefix={!iconSuffix && <SearchOutlined style={{ color: COLORS["gray-6"] }} />}
      onChange={(e) => setSearchQuery(e.target.value)}
      onPressEnter={onPressEnter}
    />
  );
}
