import { Fragment, useState } from "react";
import { DeleteModal } from "./DeleteModal";
import { useRouter } from "next/router";
import { useTheme } from "../ReactHooks";
import { useUserAction } from "../ReactHooks/useWorker";

export function DeleteButtonWithModal({
  deleteProjectAPI,
  buttonComponent,
  projectId,
  projectName,
  beforeExitFn = () => {},
  closeDrawer = () => {},
  itemName,
  mainPageRoute,
  collectAction = () => {},
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [theme] = useTheme();
  const router = useRouter();
  const [, collect] = useUserAction();

  return (
    <Fragment>
      {buttonComponent({
        onClick: () => {
          // collect("shared delete button", "click delete button", { projectId }, { projectId });
          collectAction();
          setModalVisible(true);
        },
      })}
      <DeleteModal
        name={itemName}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onDelete={deleteProjectAPI}
        onFinish={async () => {
          setModalVisible(false);
          if (theme.layout.split === false) {
            closeDrawer();
          }
          await beforeExitFn();
          if (theme.layout.split === true) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            router.push(``);
          } else if (theme.layout.split === false) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            router.push(mainPageRoute);
          }
        }}
        selectedRows={[{ key: projectId, value: projectName }]}
      />
    </Fragment>
  );
}
