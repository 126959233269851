import { Divider, Modal as AntdModal } from "antd";
import styled from "styled-components";
import { CloseIcon } from "./Icons";
import { Box } from "./Box";

const StyledModal = styled(AntdModal)`
  top: 40px;

  .ant-modal-close-x {
    display: block;
    width: 68px;
    height: 68px;
    font-size: 16px;
    font-style: normal;
    line-height: 68px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }

  .ant-modal-header {
    height: 68px;
    display: flex;
    align-items: center;
  }
`;

const FullDialogBodyStyle = {
  minHeight: "calc(100vh - 80px - 53px)",
  width: "634px",
  paddingTop: "64px",
};

const LargeDialogBodyStyle = {
  padding: "24px 80px",
};

const SmallDialogBodyStyle = {
  padding: "24px 40px",
};

export function Dialog({
  size = "large",
  children,
  title,
  footer,
  visible,
  submitting,
  onCancel,
  bodyStyle = {},
  width,
  fixedHeight,
  ...props
}) {
  let defaultWidth;
  let defaultBodyStyle;
  switch (size) {
    case "small":
      defaultWidth = "464px";
      defaultBodyStyle = SmallDialogBodyStyle;
      break;
    case "full":
      defaultWidth = "calc(100vw - 80px)";
      defaultBodyStyle = FullDialogBodyStyle;
      break;
    default:
      defaultWidth = "720px";
      defaultBodyStyle = LargeDialogBodyStyle;
  }

  return (
    <StyledModal
      title={title || null}
      footer={footer || null}
      closeIcon={<CloseIcon />}
      visible={visible}
      confirmLoading={submitting}
      onCancel={onCancel}
      width={width || defaultWidth}
      bodyStyle={{
        margin: "auto",
        ...defaultBodyStyle,
        ...bodyStyle,
      }}
      {...props}
    >
      {children}
    </StyledModal>
  );
}
