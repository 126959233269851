import Head from "next/head";
import { hotjar } from "react-hotjar";
import { useEffect, Fragment, useState, useMemo } from "react";
import { WithStore } from "../components/ReactHooks/useStore";
import { WithTheme } from "../components/ReactHooks/useTheme";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/antd.less";
import "../styles/global.css";
import "../components/SharedComponents/i18n";
import { datadogRum } from "@datadog/browser-rum";
import { globalSuccessNotification } from "../utils/frontend/utils";
import { WithWorker } from "../components/ReactHooks/useWorker";
import { isMobile, isTablet } from "react-device-detect";
import { MobileUnsupported } from "../components/MobileUnsupported";
import ProgressBar from "@badrap/bar-of-progress";
import router, { useRouter } from "next/router";
import { COLORS } from "../const";
import { createGlobalStyle } from "styled-components";
import { ErrorBoundary } from "../components/SharedComponents";
import IpCheckWrapper from "../components/IpCheckWrapper";
import { Helmet } from "react-helmet";
import SessionChecker from "../components/SessionChecker";
import BuildVersionMessage from "../components/BuildVersionMessage";

const GlobalStyle = createGlobalStyle`
  :root {
    ${Object.keys(COLORS)
      .map((key) => `--${key.toLowerCase().replace(/[%]+/gim, "")}: ${COLORS[key]};`)
      .join("\n")}
  }
`;

const progress = new ProgressBar({
  size: 2,
  color: COLORS["CK2"],
  className: "bar-of-progress",
  delay: 100,
});

router.events.on("routeChangeStart", progress.start);
router.events.on("routeChangeComplete", progress.finish);
router.events.on("routeChangeError", progress.finish);
// TODO: Check if we are going to remove below ddog init part
if (process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "skyhub",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    env: process.env.NEXT_PUBLIC_ENV,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

function gtag() {
  window.dataLayer.push(arguments);
}

// To expose HTML twitter card headers, pages below will need to bypass ip whitelist check here in _app.js
// These pages will have ip whitelist check inside.
const TWITTER_CARD_URL_WHITELIST = [
  "/signup",
  "/shared-report",
  "/shared-report/incremental",
  "/report",
  /* deprecated report urls below, kept for backward compatibility: */
  "/findings", // use /report/[reportId] instead
  "/findings/incremental", // use /report/[reportId] instead
  "/findings/marketplace", // use /report/[reportId]?reportType=marketplace instead
];

const SESSION_CHECK_BYPASS_WHITELIST = [
  "/login",
  "/signup",
  "/reset-password",
  "/shared-report",
  "/shared-report/incremental",
  "/404",
  "/landing", // All landing pages: slack, slack-support, badges
  "/privacy-policy",
];

export default function MyApp({ Component, pageProps, initialProps }) {
  const { asPath } = initialProps;
  const bypassServerSide = TWITTER_CARD_URL_WHITELIST.some((url) => asPath.includes(url));

  const router = useRouter();

  const bypassSessionCheck = useMemo(() => {
    const path = router.asPath || "";
    const bypass = SESSION_CHECK_BYPASS_WHITELIST.some((url) => path.includes(url));
    return bypass;
  }, [router]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV === "production" && typeof window !== "undefined") {
      let console = window.console || {};
      console.log = function () {};
      console.error = function () {};
    }

    if (process.env.NEXT_PUBLIC_ENV !== "development") {
      // hotjar
      hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_ID, 6);

      // Google Analytics
      window.dataLayer = window.dataLayer || [];
      gtag("js", new Date());
      gtag("config", "G-PNDNLEL9R3");
    }

    window.addEventListener("storage", (event) => {
      if (
        event.storageArea === localStorage &&
        event.key === "comment_report_downloaded" &&
        event.newValue === "true"
      ) {
        console.log(event);
        globalSuccessNotification("Comment Report Downloaded Successfully");
        window.localStorage.removeItem("comment_report_downloaded");
      }
    });
  }, []);

  if (isMobile || isTablet) {
    if (process.env.NEXT_PUBLIC_MOBILE_HOST) {
      // TODO: better handling
      const href = window.location.href.replace(
        window.location.host,
        process.env.NEXT_PUBLIC_MOBILE_HOST
      );
      window.location.replace(href);
      // stop js process
      throw SyntaxError();
    } else {
      return <MobileUnsupported />;
    }
  }

  return (
    <Fragment>
      <Head>
        <link rel="icon" href="/images/favicon.ico" />
        {process.env.NEXT_PUBLIC_ENV === "staging" && (
          <meta
            name="google-site-verification"
            content="198FfZA2rG1RDePo6NU10OiooJKyxTWW8ExBuYSQr2M"
          />
        )}
        {process.env.NEXT_PUBLIC_ENV === "staging" && <meta name="robots" content="noIndex" />}
      </Head>
      <IpCheckWrapper bypass={bypassServerSide}>
        <GlobalStyle />
        <WithStore>
          {!bypassSessionCheck && <SessionChecker />}
          <WithTheme>
            <WithWorker>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </WithWorker>
            <BuildVersionMessage />
          </WithTheme>
        </WithStore>
      </IpCheckWrapper>
    </Fragment>
  );
}

MyApp.getInitialProps = function (context) {
  const { router } = context;
  const { asPath } = router;

  return { initialProps: { asPath } };
};
