// Available locales can be remotely configurable in the future
// Key: i18n code, check https://github.com/ladjs/i18n-locales for more details
export const DEFAULT_FALLBACK_LOCALE = "en";
export const AVAILABLE_LOCALES = {
  en: {
    label: "English",
  },
  "zh-cn": {
    label: "中文",
  },
};
