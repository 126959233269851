import React, { createContext, useContext, useState, useEffect } from "react";
import { useVisibility } from "./useVisibility";
import { useStore } from "./useStore";
import { THEME } from "../../const";
import { updateUser } from "../../utils/frontend/fetchFromApi";
import deepEqual from "fast-deep-equal";

const ThemeContext = createContext([{}, () => {}]);

export function useTheme() {
  return useContext(ThemeContext);
}

const defaultTheme = {
  mode: "light",
  layout: {
    split: true,
  },
  // increment version when stucture of theme changes
  // if user has deprecated theme in localStorage
  // this is how we'll know to overwrite it
  version: 1,
};

export function WithTheme({ children }) {
  const [theme, setTheme] = useState(defaultTheme);
  const [splitViewEnabled, storeLoading] = useVisibility("splitView");
  const [store, setStore] = useStore();

  useEffect(() => {
    if (!storeLoading) {
      if (store?.userInfo?.theme) {
        setTheme((prev) => ({
          ...prev,
          ...store?.userInfo?.theme,
        }));
      }
      if (!splitViewEnabled) {
        setTheme((prev) => ({
          ...prev,
          layout: {
            ...prev.layout,
            split: false,
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitViewEnabled, storeLoading]);

  // read from localStorage
  useEffect(() => {
    const storedTheme = localStorage.getItem(THEME);
    const parsedTheme = storedTheme ? JSON.parse(storedTheme) : defaultTheme;
    const themeHasVersion = Object.hasOwnProperty.call(parsedTheme, "version");
    if (!themeHasVersion || parsedTheme.version != defaultTheme.version) {
      setTheme(defaultTheme);
    } else {
      setTheme(parsedTheme);
    }
  }, []);

  // set in localStorage whenever the theme changes
  useEffect(() => {
    localStorage.setItem(THEME, JSON.stringify(theme));
    // only update userInfo in db and store when theme change
    if (!storeLoading && (!store?.userInfo?.theme || !deepEqual(store?.userInfo?.theme, theme))) {
      updateUser(
        store?.userInfo?.userId,
        { theme },
        store?.userInfo?.authData,
        store?.userInfo?.version
      )
        .then((res) => {
          if (res.info) {
            setStore((prev) => ({
              ...prev,
              userInfo: {
                ...prev.userInfo,
                version: prev.userInfo.version + 1,
              },
            }));
          }
        })
        .catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return <ThemeContext.Provider value={[theme, setTheme]}>{children}</ThemeContext.Provider>;
}
