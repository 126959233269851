import { Skeleton, Space } from "antd";
import React, { isValidElement } from "react";
import { Table } from "./Table";

// const SKELETON_TYPE_MAP = {
//   IMAGE: (<Skeleton.Image key={column.dataIndex} active/>),
//   TEXT: (<Skeleton.Button key={column.dataIndex} active />)
// }

//  skeleton:{
//   ""
//  }

const SKELETON_TYPE_MAP = {
  IMAGE: <Skeleton.Image style={{ width: 40, height: 40, marginTop: 10 }} active />,
  TEXT: <Skeleton.Button active />,
  ICON_AND_TEXT: (
    <Space>
      <Skeleton.Avatar active />
      <Skeleton.Button active />
    </Space>
  ),
};

const DEFAULT_TYPE = "TEXT";

/**
 * @param {Object[]} columns
 * @param {Object} columns[].skeleton
 * @param {string} columns[].skeleton.type
 * @param {ReactNode} columns[].skeleton.custom
 * @param {number} rowCount
 * @param {string} size
 * @param {} className
 */
const SkeletonTable = ({
  columns,
  rowCount,
  size = "default",
  className = null,
  isGray50Bg = false,
  backgroundColor = null,
  showHeader = true,
}) => {
  return (
    <Table
      rowKey="key"
      isGray50Bg={isGray50Bg}
      backgroundColor={backgroundColor}
      size={size}
      style={{ overflow: "hidden" }}
      pagination={false}
      className={className}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column) => {
        const getSkeleton = (skeleton) => {
          if (!skeleton) {
            return SKELETON_TYPE_MAP[DEFAULT_TYPE];
          }
          const type = skeleton.type;
          const custom = skeleton.custom;
          if (custom && isValidElement(custom)) {
            return custom;
          }
          if (type && type in SKELETON_TYPE_MAP) {
            return SKELETON_TYPE_MAP[type];
          } else {
            return SKELETON_TYPE_MAP[DEFAULT_TYPE];
          }
        };

        return {
          ...column,
          render: function renderPlaceholder() {
            return getSkeleton(column.skeleton);
          },
          width: column.width || "",
        };
      })}
      showHeader={showHeader}
      data-testid="table-skeleton"
    />
  );
};

export default SkeletonTable;
