import { RefreshIcon } from "../SharedComponents/Icons";
import React from "react";
import { Button } from "../SharedComponents/Button";

export function RefreshButton({ onClick, style }) {
  return (
    <Button
      type="iconOnly"
      icon={<RefreshIcon size={20} />}
      onClick={onClick}
      testid="refresh-button"
      style={style}
    />
  );
}
