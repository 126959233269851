import i18next from "./i18n";
import { AUDIT_STATUS, AUDIT_STATUS_LABELS, resolveAuditStatus } from "../../const";
import { getReportType, ReportType } from "../../utils/common/report";
import Link from "next/link";
import React from "react";
import { AuditAction, AuditEventIcon, AuditStepProgress } from "../../const/audit";

const getDefaultSteps = () => [
  {
    number: 1,
    title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.0.title"),
    timestamp: null,
    description: null,
    events: [
      {
        type: "event",
        icon: AuditEventIcon.INFO,
        title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.0.events.0.title"),
      },
    ],
    progress: AuditStepProgress.INCOMPLETE,
  },
  {
    number: 2,
    title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.1.title"),
    timestamp: null,
    description: null,
    events: [
      {
        type: "event",
        icon: AuditEventIcon.INFO,
        title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.1.events.0.title"),
      },
    ],
    progress: AuditStepProgress.INCOMPLETE,
  },
  {
    number: 3,
    title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.2.title"),
    timestamp: null,
    description: null,
    events: [
      {
        type: "event",
        icon: AuditEventIcon.INFO,
        title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.2.events.0.title"),
      },
    ],
    progress: AuditStepProgress.INCOMPLETE,
  },
  {
    number: 4,
    title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.3.title"),
    timestamp: null,
    description: null,
    events: [
      {
        type: "event",
        icon: AuditEventIcon.INFO,
        title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.3.events.0.title"),
      },
    ],
    progress: AuditStepProgress.INCOMPLETE,
  },
  {
    number: 5,
    title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.4.title"),
    timestamp: null,
    description: null,
    events: [
      {
        type: "event",
        icon: AuditEventIcon.INFO,
        title: i18next.t("utils.auditStatusFlowHelper.getDefaultSteps.4.events.0.title"),
      },
    ],
    tasks: [],
    progress: AuditStepProgress.INCOMPLETE,
  },
];

function filterProgressItemsByStep(progress, step, projectInfo) {
  const currentOrder = AUDIT_STATUS_LABELS[projectInfo.status].order;
  switch (step) {
    case 0: {
      // substeps = NEW, QUOTE_SUBMITTED, QUOTE_APPROVED
      const events = [];

      const newItems = progress
        .filter(
          (x) =>
            x.field === "status" &&
            (x.to === AUDIT_STATUS.NEW ||
              x.to === AUDIT_STATUS.QUOTE_SUBMITTED ||
              x.to === AUDIT_STATUS.QUOTE_APPROVED)
        )
        .sort((a, b) => a.createdAt - b.createdAt)
        .map((x) => {
          return {
            type: "event",
            icon: AuditEventIcon.CHECK,
            action: null,
            timestamp: x.createdAt,
            reportFileName: null,
            title: (
              <>
                {i18next.t("utils.auditStatusFlowHelper.step1.events.0.title") + " "}
                <a href="https://www.certik.com/" target="_blank" rel="noreferrer">
                  CertiK.com
                </a>
              </>
            ),
          };
        });
      // if (newItems && newItems.length > 0) {
      //   events.push(newItems[newItems.length - 1]);
      //   if (currentOrder === 0) {
      //     events.push({
      //       type: "event",
      //       icon: AuditEventIcon.INFO,
      //       title: "Waiting for quote to be submitted.",
      //     });
      //     return events;
      //   }
      // }

      if (newItems && newItems.length > 0) {
        events.push(newItems[newItems.length - 1]);
      }
      // const quoteSubmittedItems = progress
      //   .filter((x) => x.field === "status" && x.to === AUDIT_STATUS.QUOTE_SUBMITTED)
      //   .sort((a, b) => a.createdAt - b.createdAt)
      //   .map((x) => {
      //     return {
      //       type: "event",
      //       icon: AuditEventIcon.CHECK,
      //       action: null,
      //       timestamp: x.createdAt,
      //       reportFileName: null,
      //       title: AUDIT_STATUS_LABELS[x.to].description,
      //     } as AuditEvent;
      //   });

      // if (quoteSubmittedItems && quoteSubmittedItems.length > 0) {
      //   events.push(quoteSubmittedItems[quoteSubmittedItems.length - 1]);
      //   if (currentOrder === 1) {
      //     events.push({
      //       type: "event",
      //       icon: AuditEventIcon.INFO,
      //       title: "Waiting for quote to be approved.",
      //     });
      //     return events;
      //   }
      // }

      // const quoteApprovedItems = progress
      //   .filter((x) => x.field === "status" && x.to === AUDIT_STATUS.QUOTE_APPROVED)
      //   .sort((a, b) => a.createdAt - b.createdAt)
      //   .map((x) => {
      //     return {
      //       type: "event",
      //       icon: AuditEventIcon.CHECK,
      //       action: null,
      //       timestamp: x.createdAt,
      //       reportFileName: null,
      //       title: AUDIT_STATUS_LABELS[x.to].description,
      //     } as AuditEvent;
      //   });

      // if (quoteApprovedItems && quoteApprovedItems.length > 0) {
      //   events.push(quoteApprovedItems[quoteApprovedItems.length - 1]);
      //   if (currentOrder === 2) {
      //     events.push({
      //       type: "alert",
      //       level: "warning",
      //       backgroundColor: "#FFFEF6",
      //       highlightColor: "#F9A825",
      //       description:
      //         "No additional actions are required. We will get back to you as soon as we have the final report published.",
      //       iconVisible: false,
      //       centeredIcon: false,
      //     } as AuditAlert);
      //   }
      // }

      return events;
    }
    case 1: {
      // substeps = AUDIT_STARTED
      const events = [];
      const items = progress
        .filter((x) => x.field === "status" && [AUDIT_STATUS.AUDIT_STARTED].includes(x.to))
        .sort((a, b) => a.createdAt - b.createdAt)
        .map((x) => {
          return {
            type: "event",
            icon: AuditEventIcon.CHECK,
            timestamp: x.createdAt,
            title: i18next.t("utils.auditStatusFlowHelper.step2.events.0.title"),
          };
        });

      events.push(items[items.length - 1]);

      if (incrementalReportLocationIndicator(projectInfo) === 1) {
        events.push({
          type: "event",
          icon: AuditEventIcon.INFO,
          action: null,
          timestamp: projectInfo.incrementalReport.reportItem.uploadTime,
          reportFileName: "Stepwise Report",
          title: i18next.t("utils.auditStatusFlowHelper.step2.events.2.title"),
          reportType: projectInfo.incrementalReport.reportItem.reportType,
        });
      }

      if (projectInfo.status !== AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF) {
        events.push({
          type: "task",
          completed: projectInfo.accountsConfirmation?.checked || false,
          hasButton: false,
          icon: AuditEventIcon.CHECKBOX,
          title: i18next.t("utils.auditStatusFlowHelper.step2.events.1.title"),
          action: AuditAction.FILL_ACCOUNT_INFO,
          timestamp: projectInfo.accountsConfirmation?.updatedAt,
        });
      }

      return events;
    }
    case 2: {
      const events = [];

      // the first state is just `Pre-report (version) ready`
      const preReports = (projectInfo.auditReportList || [])
        .filter((report) => getReportType(report.fileName) === ReportType.PRE)
        .sort((a, b) => b.uploadTime - a.uploadTime);

      if (preReports.length === 0) {
        // this should not happen...
        // handle error
        console.log("Status is PRE_REPORT_READY but there's no pre reports");
        return events;
      }

      const version = preReports.length;
      const latestPreReport = preReports[0];

      events.push({
        type: "event",
        icon: AuditEventIcon.INFO,
        action: null,
        timestamp: latestPreReport.uploadTime,
        reportFileName: latestPreReport.fileName,
        title: i18next.t("utils.auditStatusFlowHelper.step3.events.0.title", { version: version }),
      });

      if (incrementalReportLocationIndicator(projectInfo) === 2) {
        events.push({
          type: "event",
          icon: AuditEventIcon.INFO,
          action: null,
          timestamp: projectInfo.incrementalReport.reportItem.uploadTime,
          reportFileName: "Stepwise Report",
          title: i18next.t("utils.auditStatusFlowHelper.step2.events.2.title"),
          reportType: projectInfo.incrementalReport.reportItem.reportType,
        });
      }

      if (preReports.length > 1) {
        events.push({
          type: "task",
          completed: true,
          icon: AuditEventIcon.INFO,
          action: AuditAction.VIEW_REPORTS_TAB,
          hasButton: true,
          title: (
            <>
              {i18next.t("utils.auditStatusFlowHelper.step3.events.1.title.0") + " "}
              <Link passHref href={`/audits/projects?projectId=${projectInfo.id}&tab=reports`}>
                <a>{i18next.t("utils.auditStatusFlowHelper.step3.events.1.title.1")}</a>
              </Link>
              {" " + i18next.t("utils.auditStatusFlowHelper.step3.events.1.title.2")}
            </>
          ),
          timestamp: null,
        });
      }

      // determine if the report has been checked
      // if it has, show it as a completed task,
      // else show it as a task
      const reportChecked = latestPreReport.reportConfirmation?.checked || false;
      events.push({
        type: "task",
        completed: reportChecked,
        hasButton: false,
        icon: AuditEventIcon.CHECKBOX,
        title: i18next.t("utils.auditStatusFlowHelper.step3.events.2.title"),
        action: AuditAction.CHECK_REPORT,
        reportId: latestPreReport.id,
        timestamp: latestPreReport.reportConfirmation?.updatedAt,
      });

      // determine if the report can be signed off
      const showSignOff = !!latestPreReport.showSignOff;
      if (showSignOff) {
        events.push({
          type: "task",
          buttonLabel: i18next.t("utils.auditStatusFlowHelper.step3.events.3.button"),
          completed: !!latestPreReport.signedOff?.value,
          hasButton: !latestPreReport.signedOff?.value,
          timestamp: latestPreReport.signedOff?.value ? latestPreReport.signedOff?.updatedAt : null,
          action: AuditAction.SIGN_OFF,
          icon: AuditEventIcon.CHECKBOX,
          title: i18next.t("utils.auditStatusFlowHelper.step3.events.3.title"),
          reportId: latestPreReport.id,
        });
      }

      return events;
    }
    case 3: {
      const events = [];

      const preReportSignedOffItems = progress
        .filter((x) => x.field === "status" && x.to === AUDIT_STATUS.PRE_REPORT_SIGNED_OFF)
        .sort((a, b) => b.createdAt - a.createdAt);

      //TODO:
      if (preReportSignedOffItems.length > 0) {
        events.push({
          type: "event",
          icon: AuditEventIcon.CHECK,
          title: i18next.t("utils.auditStatusFlowHelper.step4.events.0.title"),
          reportFileName: null,
          action: null,
          timestamp: preReportSignedOffItems[0].createdAt,
        });
      }

      // the first state is just `Pre-report (version) ready`
      const finalReports = (projectInfo.auditReportList || [])
        .filter((report) => getReportType(report.fileName) === ReportType.REP)
        .sort((a, b) => b.uploadTime - a.uploadTime);

      if (finalReports.length === 0) {
        // this should not happen...
        // handle error
        // console.error("Status is PRE_REPORT_READY but there's no pre reports");
        return events;
      }

      const version = finalReports.length;
      const latestFinalReport = finalReports[0];

      events.push({
        type: "event",
        icon: AuditEventIcon.INFO,
        action: null,
        timestamp: latestFinalReport.uploadTime,
        reportFileName: latestFinalReport.fileName,
        title: i18next.t("utils.auditStatusFlowHelper.step4.events.1.title", { version: version }),
      });

      if (
        incrementalReportLocationIndicator(projectInfo) === 3 &&
        resolveAuditStatus(projectInfo?.status) === AUDIT_STATUS.FINAL_REPORT_READY
      ) {
        events.push({
          type: "event",
          icon: AuditEventIcon.INFO,
          action: null,
          timestamp: projectInfo.incrementalReport.reportItem.uploadTime,
          reportFileName: "Stepwise Report",
          title: i18next.t("utils.auditStatusFlowHelper.step2.events.2.title"),
          reportType: projectInfo.incrementalReport.reportItem.reportType,
        });
      }

      // determine if the report has been checked
      // if it has, show it as a completed task,
      // else show it as a task
      const reportChecked = latestFinalReport.reportConfirmation?.checked || false;
      events.push({
        type: "task",
        completed: reportChecked,
        hasButton: false,
        icon: AuditEventIcon.CHECKBOX,
        title: i18next.t("utils.auditStatusFlowHelper.step4.events.2.title"),
        action: AuditAction.CHECK_REPORT,
        reportId: latestFinalReport.id,
        timestamp: latestFinalReport.reportConfirmation?.updatedAt,
      });

      // determine if the report can be signed off
      const showSignOff = !!latestFinalReport.showSignOff;
      if (showSignOff) {
        events.push({
          type: "task",
          buttonLabel: i18next.t("utils.auditStatusFlowHelper.step4.events.3.button"),
          completed: !!latestFinalReport.signedOff?.value,
          hasButton: !latestFinalReport.signedOff?.value,
          timestamp: latestFinalReport.signedOff?.value
            ? latestFinalReport.signedOff?.updatedAt
            : null,
          action: AuditAction.SIGN_OFF,
          icon: AuditEventIcon.CHECKBOX,
          title: i18next.t("utils.auditStatusFlowHelper.step4.events.3.title"),
          reportId: latestFinalReport.id,
        });
      }

      return events;
    }
    case 4: {
      const events = [];

      const finalReports = (projectInfo.auditReportList || [])
        .filter((report) => getReportType(report.fileName) === ReportType.REP)
        .sort((a, b) => b.uploadTime - a.uploadTime);

      if (finalReports.length === 0) {
        // this should not happen...
        // handle error
        // console.error("Status is PRE_REPORT_READY but there's no pre reports");
        return events;
      }

      const latestFinalReport = finalReports[0];

      events.push({
        type: "event",
        icon: AuditEventIcon.INFO,
        action: null,
        timestamp: null,
        reportFileName: latestFinalReport.fileName,
        title: projectInfo.clientRequestToPublish
          ? i18next.t("utils.auditStatusFlowHelper.step5.events.0.title")
          : i18next.t("utils.auditStatusFlowHelper.step5.events.1.title"),
      });

      if (
        incrementalReportLocationIndicator(projectInfo) === 3 &&
        resolveAuditStatus(projectInfo?.status) !== AUDIT_STATUS.FINAL_REPORT_READY
      ) {
        events.push({
          type: "event",
          icon: AuditEventIcon.INFO,
          action: null,
          timestamp: null,
          reportFileName: "Stepwise Report",
          title: i18next.t("utils.auditStatusFlowHelper.step2.events.2.title"),
          reportType: projectInfo.incrementalReport.reportItem.reportType,
        });
      }

      if (projectInfo.clientRequestToPublish) {
        if (projectInfo.publishedLink) {
          events.push({
            type: "event",
            icon: AuditEventIcon.INFO,
            action: AuditAction.VIEW_PUBLIC_REPORT,
            timestamp: null,
            reportFileName: null,
            title: i18next.t("utils.auditStatusFlowHelper.step5.events.2.title"),
          });

          events.push({
            type: "event",
            icon: AuditEventIcon.INFO,
            timestamp: null,
            reportFileName: null,
            title: "To share your audit report on social channels",
            action: AuditAction.SHARE_TWITTER,
          });
        }

        events.push({
          type: "task",
          icon: AuditEventIcon.INFO,
          action: AuditAction.UNPUBLISH,
          timestamp: null,
          reportId: latestFinalReport.id,
          completed: false,
          hasButton: true,
          title: i18next.t("utils.auditStatusFlowHelper.step5.events.3.title"),
        });
      } else {
        events.push({
          type: "task",
          icon: AuditEventIcon.INFO,
          action: AuditAction.PUBLISH,
          timestamp: null,
          reportId: latestFinalReport.id,
          completed: false,
          hasButton: true,
          title: i18next.t("utils.auditStatusFlowHelper.step5.events.4.title"),
        });
      }

      const completedEvents = progress
        .filter((x) => x.field === "status" && x.to === AUDIT_STATUS.COMPLETED)
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((x) => {
          return {
            type: "event",
            icon: AuditEventIcon.CHECK,
            action: null,
            timestamp: x.createdAt,
            reportFileName: null,
            title: i18next.t("utils.auditStatusFlowHelper.step5.events.5.title"),
          };
        });

      if (projectInfo.status === AUDIT_STATUS.COMPLETED && completedEvents.length > 0) {
        events.push(completedEvents[0]);
      }

      if (projectInfo.status === AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF) {
        events.push({
          type: "task",
          completed: projectInfo.accountsConfirmation?.checked || false,
          hasButton: false,
          icon: AuditEventIcon.CHECKBOX,
          title: i18next.t("utils.auditStatusFlowHelper.step2.events.1.title"),
          action: AuditAction.FILL_ACCOUNT_INFO,
          timestamp: projectInfo.accountsConfirmation?.updatedAt,
        });
      }

      return events;
    }
    default:
      return [];
  }
}

export function constructAuditStatusState(projectInfo, progress) {
  const steps = getDefaultSteps();
  for (let i = 0; i <= 4; i++) {
    processStep(steps, i, projectInfo, progress);
  }

  // apply timestamp to the first step (project created at)
  steps[0].timestamp = projectInfo.createdAt;

  return steps;
}

function resetEvents(steps, indices) {
  const defaultSteps = getDefaultSteps();
  indices.forEach((index) => {
    steps[index] = defaultSteps[index];
  });
}

function disableTasks(steps, indidces) {
  indidces.forEach((index) => {
    for (const event of steps[index].events) {
      if (event.type === "task") {
        event.disabled = true;
      }
    }
  });
}

function getTimestamp(progress, status) {
  const times = progress
    .filter((x) => x.field === "status" && [status].includes(x.to))
    .sort((a, b) => a.createdAt - b.createdAt);
  return times[0]?.createdAt;
}

function processStep(
  /** the state of the audit state flow component (array gets modified) */
  steps,
  /** the iteration index */
  i,
  /** the project data */
  projectInfo,
  progress
) {
  // get the events related to this step
  const events = filterProgressItemsByStep(progress, i, projectInfo).filter((x) => x);

  // assign the events to this step if there are any
  if (events && events.length > 0) {
    steps[i].events = events;
    // if possible, assign the step's timestamp (displayed next to title)
    // const eventsWithTimestamp = events.filter((x) => x?.timestamp);
    // if (eventsWithTimestamp.length > 0) {
    //   const latestTimestamp = eventsWithTimestamp.slice(-1)[0].timestamp;
    //   steps[i].timestamp = latestTimestamp;
    // }
  }

  // figure out the steps completion status, as well as its title
  switch (projectInfo.status) {
    case AUDIT_STATUS.NEW:
      steps[0].progress = AuditStepProgress.IN_PROGRESS;
      resetEvents(steps, [1, 2, 3, 4]);
      break;
    case AUDIT_STATUS.QUOTE_SUBMITTED:
      steps[0].progress = AuditStepProgress.IN_PROGRESS;
      resetEvents(steps, [1, 2, 3, 4]);
      break;
    case AUDIT_STATUS.QUOTE_APPROVED:
      steps[0].progress = AuditStepProgress.IN_PROGRESS;
      resetEvents(steps, [1, 2, 3, 4]);
      break;
    case AUDIT_STATUS.AUDIT_STARTED:
      steps[0].progress = AuditStepProgress.COMPLETE;

      steps[1].progress = AuditStepProgress.IN_PROGRESS;
      steps[1].title = i18next.t("utils.auditStatusFlowHelper.processStep.auditInProgress");

      steps[1].timestamp = getTimestamp(progress, AUDIT_STATUS.AUDIT_STARTED);

      resetEvents(steps, [2, 3, 4]);
      disableTasks(steps, [0]);
      break;
    case AUDIT_STATUS.PRE_REPORT_READY:
      steps[0].progress = AuditStepProgress.COMPLETE;

      steps[1].progress = AuditStepProgress.COMPLETE;
      steps[1].title = i18next.t("utils.auditStatusFlowHelper.processStep.auditSectionComplete");

      steps[2].progress = AuditStepProgress.IN_PROGRESS;
      steps[2].title = i18next.t("utils.auditStatusFlowHelper.processStep.preReportReady");

      steps[1].timestamp = getTimestamp(progress, AUDIT_STATUS.AUDIT_STARTED);
      steps[2].timestamp = getTimestamp(progress, AUDIT_STATUS.PRE_REPORT_READY);

      resetEvents(steps, [3, 4]);
      disableTasks(steps, [0, 1]);
      break;
    case AUDIT_STATUS.PRE_REPORT_SIGNED_OFF:
      steps[0].progress = AuditStepProgress.COMPLETE;

      steps[1].progress = AuditStepProgress.COMPLETE;
      steps[1].title = i18next.t("utils.auditStatusFlowHelper.processStep.auditSectionComplete");

      steps[2].progress = AuditStepProgress.COMPLETE;
      steps[2].title = i18next.t("utils.auditStatusFlowHelper.processStep.preReportApproved");

      steps[3].progress = AuditStepProgress.IN_PROGRESS;
      steps[3].title = i18next.t("utils.auditStatusFlowHelper.processStep.finalReportInProgress");

      steps[1].timestamp = getTimestamp(progress, AUDIT_STATUS.AUDIT_STARTED);
      steps[2].timestamp = getTimestamp(progress, AUDIT_STATUS.PRE_REPORT_READY);
      steps[3].timestamp = getTimestamp(progress, AUDIT_STATUS.PRE_REPORT_SIGNED_OFF);

      resetEvents(steps, [4]);
      disableTasks(steps, [0, 1, 2]);
      break;
    case AUDIT_STATUS.FINAL_REPORT_READY:
      steps[0].progress = AuditStepProgress.COMPLETE;

      steps[1].progress = AuditStepProgress.COMPLETE;
      steps[1].title = i18next.t("utils.auditStatusFlowHelper.processStep.auditSectionComplete");

      steps[2].progress = AuditStepProgress.COMPLETE;
      steps[2].title = i18next.t("utils.auditStatusFlowHelper.processStep.preReportApproved");

      steps[3].progress = AuditStepProgress.IN_PROGRESS;
      steps[3].title = i18next.t("utils.auditStatusFlowHelper.processStep.finalReportReady");

      steps[1].timestamp = getTimestamp(progress, AUDIT_STATUS.AUDIT_STARTED);
      steps[2].timestamp = getTimestamp(progress, AUDIT_STATUS.PRE_REPORT_READY);
      steps[3].timestamp =
        getTimestamp(progress, AUDIT_STATUS.PRE_REPORT_SIGNED_OFF) ||
        getTimestamp(progress, AUDIT_STATUS.FINAL_REPORT_READY);

      resetEvents(steps, [4]);
      disableTasks(steps, [0, 1, 2]);
      break;
    case AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF:
      steps[0].progress = AuditStepProgress.COMPLETE;

      steps[1].progress = AuditStepProgress.COMPLETE;
      steps[1].title = i18next.t("utils.auditStatusFlowHelper.processStep.auditSectionComplete");

      steps[2].progress = AuditStepProgress.COMPLETE;
      steps[2].title = i18next.t("utils.auditStatusFlowHelper.processStep.preReportApproved");

      steps[3].progress = AuditStepProgress.COMPLETE;
      steps[3].title = i18next.t("utils.auditStatusFlowHelper.processStep.finalReportApproved");

      steps[4].progress = AuditStepProgress.IN_PROGRESS;
      if (projectInfo.clientRequestToPublish) {
        if (!projectInfo.publishedLink) {
          steps[4].title = i18next.t(
            "utils.auditStatusFlowHelper.processStep.finalReportPublishInProgress"
          );
        } else {
          steps[4].title = i18next.t(
            "utils.auditStatusFlowHelper.processStep.finalReportPublished"
          );
        }
      } else {
        steps[4].title = i18next.t("utils.auditStatusFlowHelper.processStep.finalReportCompleted");
      }

      steps[1].timestamp = getTimestamp(progress, AUDIT_STATUS.AUDIT_STARTED);
      steps[2].timestamp = getTimestamp(progress, AUDIT_STATUS.PRE_REPORT_READY);
      steps[3].timestamp =
        getTimestamp(progress, AUDIT_STATUS.PRE_REPORT_SIGNED_OFF) ||
        getTimestamp(progress, AUDIT_STATUS.FINAL_REPORT_READY);
      steps[4].timestamp = getTimestamp(progress, AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF);

      disableTasks(steps, [0, 1, 2, 3]);
      break;
    case AUDIT_STATUS.COMPLETED:
      steps[0].progress = AuditStepProgress.COMPLETE;

      steps[1].progress = AuditStepProgress.COMPLETE;
      steps[1].title = i18next.t("utils.auditStatusFlowHelper.processStep.auditSectionComplete");

      steps[2].progress = AuditStepProgress.COMPLETE;
      steps[2].title = i18next.t("utils.auditStatusFlowHelper.processStep.preReportApproved");

      steps[3].progress = AuditStepProgress.COMPLETE;

      steps[4].progress = AuditStepProgress.COMPLETE;

      steps[1].timestamp = getTimestamp(progress, AUDIT_STATUS.AUDIT_STARTED);
      steps[2].timestamp = getTimestamp(progress, AUDIT_STATUS.PRE_REPORT_READY);
      steps[3].timestamp =
        getTimestamp(progress, AUDIT_STATUS.PRE_REPORT_SIGNED_OFF) ||
        getTimestamp(progress, AUDIT_STATUS.FINAL_REPORT_READY);
      steps[4].timestamp =
        getTimestamp(progress, AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF) ||
        getTimestamp(progress, AUDIT_STATUS.COMPLETED);

      disableTasks(steps, [0, 1, 2, 3]);
      break;
  }
}

/* Determine the location of stepwise report
 * If no stepwise report, return 0
 * Else if final exists and latest stepwise is later than latest final, return 3
 * Else if pre exists and latest stepwise is later than latest pre, return 2
 * Else return 1
 */
function incrementalReportLocationIndicator(projectInfo) {
  if (!projectInfo?.incrementalReport?.reportItem) {
    return 0;
  }
  const incrementalReportCreatedAt = projectInfo.incrementalReport.reportItem?.uploadTime || 0;
  const preReports = (projectInfo.auditReportList || [])
    .filter((report) => getReportType(report.fileName) === ReportType.PRE)
    .sort((a, b) => b.uploadTime - a.uploadTime);
  const finalReports = (projectInfo.auditReportList || [])
    .filter((report) => getReportType(report.fileName) === ReportType.REP)
    .sort((a, b) => b.uploadTime - a.uploadTime);
  if (finalReports?.length && incrementalReportCreatedAt >= finalReports[0]?.uploadTime) {
    return 3;
  }
  if (preReports?.length && incrementalReportCreatedAt >= preReports[0]?.uploadTime) {
    return 2;
  }
  return 1;
}
