export const CROSS_SALE_TYPE = {
  twitter: "twitter",
  contractRegistry: "contract registry",
  skynet: "skynet",
  advancedAlerting: "advanced alerting",
  kycService: "kyc service",
  publish: "publish",
  freeBugBounty: "free bug bounty",
  emblem: "emblem",
};
