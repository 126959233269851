export const AVATAR_COLOR_LIST = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

export const TAG_COLOR_MAPPING = {
  gold: {
    color: "#d48806",
    backgroundColor: "#fffbe6",
    borderColor: "#ffe58f",
  },
  green: {
    color: "#389e0d",
    backgroundColor: "#f6ffed",
    borderColor: "#b7eb8f",
  },
  orange: {
    color: "#fa8c16",
    backgroundColor: "#fff7e6",
    borderColor: "#ffd591",
  },
  blue: {
    color: "#1890ff",
    backgroundColor: "#e7f3ff",
    borderColor: "#9bcfff",
  },
  red: {
    color: "#f5222d",
    backgroundColor: "#fff1f0",
    borderColor: "#ffa39e",
  },
  yellow: {
    color: "#92400e",
    backgroundColor: "#fef3c7",
    borderColor: "#ffe58f",
  },
  gray: {
    color: "#595959",
    backgroundColor: "#f5f5f5",
    borderColor: "#d9d9d9",
  },
  default: {
    color: "#000000d9",
    backgroundColor: "#fafafa",
    borderColor: "#d9d9d9",
  },
};

export const COLORS = {
  // ck2-gray
  "gray-50": "#FAFAFA",
  "gray-100": "#F5F5F5",
  "gray-200": "#EEEEEE",
  "gray-300": "#E0E0E0",
  "gray-400": "#BDBDBD",
  "gray-500": "#9E9E9E",
  "gray-600": "#757575",
  "gray-700": "#616161",
  "gray-800": "#424242",
  "gray-900": "#212121",
  "gray-ext1": "#111111",
  "gray-ext2": "#000000",
  white: "#FFFFFF",

  // ck2-red
  "red-50": "#FFEBEE",
  "red-100": "#FFCDD2",
  "red-200": "#EF9A9A",
  "red-300": "#E57373",
  "red-400": "#EF5350",
  "red-500": "#E35141",
  "red-600": "#E53935",
  "red-700": "#D32F2F",
  "red-800": "#C62828",
  "red-900": "#B71C1C",

  // ck2-yellow
  "yellow-50": "#FFFDE7",
  "yellow-100": "#FFF9C4",
  "yellow-200": "#FFF59D",
  "yellow-300": "#FFF176",
  "yellow-400": "#FFEE58",
  "yellow-500": "#FFEB3B",
  "yellow-600": "#FDD835",
  "yellow-700": "#FBC02D",
  "yellow-800": "#F9A825",
  "yellow-900": "#F57F17",

  // ck2-green
  "green-50": "#E8F5E9",
  "green-100": "#C8E6C9",
  "green-200": "#A5D6A7",
  "green-300": "#81C784",
  "green-400": "#66BB6A",
  "green-500": "#4CAF50",
  "green-600": "#43A047",
  "green-700": "#388E3C",
  "green-800": "#2E7D32",
  "green-900": "#1B5E20",

  blue: "#4D6380",
  lightBlue: "#508BD0",
  deepBlue: "#364559",
  navy: "#263240",
  blueLow: "#9BCFFF",
  gold: "#E1AB4C",
  darkGold: "#CF9734",
  darkerGold: "#D48806",
  yellowMedium: "#FFD591",
  greenLow: "#B7EB8F",
  green: "#389E0D",
  redHigh: "#FFA39E",
  lightGray: "#F0F2F5",
  certikRed: "#E5453D",
  purple: "#854ADE",
  darkPurple: "#531DAB",
  "blue-1": "#BAE7FF",
  "blue-2": "#91D5FF",
  "blue-4": "#40A9FF",
  "blue-6": "#1890FF",
  "blue-7": "#096DD9",
  "blue-8": "#0050B3",
  "blue-9": "#003A8C",
  "blue-10": "#C5CAE9",
  "blue-11": "#B3E5FC",
  "cyan-5": "#13C2C2",
  "cyan-7": "#08979C",
  "cyan-8": "#006D75",
  "gold-6": "#FAAD14",

  "gray-1": "#FFFFFF",
  "gray-2": "#FAFAFA",
  "gray-3": "#F5F5F5",
  "gray-4": "#E8E8E8",
  "gray-5": "#D9D9D9",
  "gray-6": "#BFBFBF",
  "gray-7": "#8C8C8C",
  "gray-8": "#595959",
  "gray-8-half": "#333333",
  "gray-9": "#262626",
  "gray-10": "#000000",
  "gray-11": "#00000008",
  "gray-12": "#9E9E9E",
  "green-1": "#F4FCEE",
  "green-2": "#2FC25B",
  "green-3": "#75BF3D",
  "green-4": "#7DB307",
  "green-7": "#389E0D",
  "green-8": "#C8E6C9",
  "green-9": "#F6FFED",
  "magenta-3": "#C41D7F",
  "magenta-6": "#EB2F96",
  "orange-1": "#FFF7E6",
  "orange-2": "#FFF2E8",
  "orange-5": "#FFA940",
  "orange-6": "#FA8C16",
  "orange-7": "#E58C3D",
  "red-2": "#FFF1F1",
  "red-4": "#F3541D",
  "red-5": "#FF4D4F",
  "red-6": "#F5222D",
  "red-7": "#E14C4C",
  "red-8": "#CF1322",
  "red-9": "#fae1e3",
  "red-10": "#F2DFE2",

  "red-1000": "rgba(229, 69, 61, 0.5)", // not in spec
  CK2: "#E5453D",
  "CK2-yellow": "#F9A825",

  "white-87%": "rgba(255,255,255, 0.87)",
  "amber-700": "#FFA200",
  "blue-800": "#01579B",
  "CK2-green": "#2E7D32",
  "CK2-cyan": "#1B8B99",
  "inidigo-400": "#5C6BC0",
  "light-blue-50": "#E1F5FE",
  "teal-400": "#26A69A",
  "lightBlue-400": "#29B6F6",
  "indigo-800": "#283593",
};
