import styled from "styled-components";

const CenterWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(white);
`;

export function MobileUnsupported() {
  return (
    <CenterWrapper>
      <img
        src="https://client-portal-public-facing.s3.us-east-2.amazonaws.com/MobileWhite.svg"
        alt="mobileNotSupported"
      />
    </CenterWrapper>
  );
}
