import React, { createContext, useContext, useState, useEffect } from "react";
import { useStore } from "./useStore";
import {
  VALID_STATUS_SET,
  NO_AUDIT_TEAM_FILTER_LABEL,
  AUDIT_ADVANCED_SEARCH,
  ADMIN_PROJECT_ADVANCED_SEARCH,
  UNRESPONSIVE_STATUS_ROLE_WHITELIST,
} from "../../const";
import {
  fetchProjects,
  fetchProjectsWithLastComment,
  fetchProjectCountByStatus,
} from "../../utils/frontend/fetchFromApi";
import {
  countUnique,
  countUniqueInNestedArray,
  parseLocalStorageItemIntoJson,
} from "../../utils/frontend/utils";
import { useProjectDrawer } from "./useProjectDrawer";
import {
  DEFAULT_STATE,
  DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS,
} from "../SharedComponents/AdvancedSearch/Shared";

const ProjectContext = createContext([{}, () => {}]);

export function useProject() {
  return useContext(ProjectContext);
}

// creates an object where the keys are audit statuses and the value is an empty array
const defaultProjectsState = () =>
  [...VALID_STATUS_SET].reduce((accumulator, status) => ({ ...accumulator, [status]: [] }), {
    all: [],
  });

const DEFAULT_STATE_FOR_BACKEND = {
  statusList: Object.keys(DEFAULT_STATE.projectStatus).filter(
    (status) => status !== "all" && DEFAULT_STATE.projectStatus[status] === true
  ),
  timeRange: DEFAULT_STATE.timeRange,
  orderBy: DEFAULT_STATE.orderBy,
};

const DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS_FOR_BACKEND = {
  statusList: Object.keys(DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS.projectStatus).filter(
    (status) =>
      status !== "all" && DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS.projectStatus[status] === true
  ),
  timeRange: DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS.timeRange,
  orderBy: DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS.orderBy,
};

export function WithProject({ children, withLastComment = false, forAuditOverview = false }) {
  const [store] = useStore();
  const [selectedStatus, setSelectedStatus] = useState("all"); // AUDIT_STATUS or "all"
  const [projects, setProjects] = useState(defaultProjectsState());
  const [isLoading, setIsLoading] = useState(true);
  const [certikTeams, setCertikTeams] = useState({});
  const [tenants, setTenants] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [hasNewProject, setHasNewProject] = useState(false);
  const tableRefreshSymbol = useProjectDrawer((state) => state.tableRefreshSymbol);
  const [countLoading, setCountLoading] = useState(true);
  const [countByStatusMap, setCountByStatusMap] = useState({});
  const [advancedSearch, setAdvancedSearch] = useState(null);

  const role = store?.userInfo?.role;

  const getProjects = async () => {
    if (!store.userInfo) return;
    setIsLoading(true);
    const fetchProjectsApi = withLastComment ? fetchProjectsWithLastComment : fetchProjects;
    const projects =
      (await fetchProjectsApi(
        store?.userInfo?.userId,
        store?.userInfo?.authData,
        // For overview page, we need all the projects data
        forAuditOverview ? DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS_FOR_BACKEND : advancedSearch
      )) || [];
    const groupedProjects = defaultProjectsState();
    projects.forEach((project, index, projects) => {
      const keyedProject = { key: index, ...projects[index] };
      groupedProjects["all"].push(keyedProject);
      groupedProjects[projects[index].status].push(keyedProject);
    });

    setCertikTeams(
      countUniqueInNestedArray(
        projects,
        (project) => project.certikTeamNameList,
        true,
        NO_AUDIT_TEAM_FILTER_LABEL
      )
    );
    setTenants(countUnique(projects, (project) => project.tenantName));
    setProjects(groupedProjects);
    setIsLoading(false);
  };

  const getProjectCountByStatus = async () => {
    if (!store.userInfo) return;
    setCountLoading(true);
    const resp = await fetchProjectCountByStatus(
      store.userInfo?.userId,
      advancedSearch,
      withLastComment ? "admin" : "audit",
      store.userInfo?.authData
    );
    setCountByStatusMap({
      ...resp,
      all: Object.values(resp).reduce((accu, cur) => accu + cur, 0),
    });
    setCountLoading(false);
  };

  useEffect(() => {
    advancedSearch && getProjects();
    advancedSearch && getProjectCountByStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.userInfo, store.projectList, tableRefreshSymbol, advancedSearch]);

  useEffect(() => {
    if (role != null) {
      const targetDefaultState = UNRESPONSIVE_STATUS_ROLE_WHITELIST.includes(role)
        ? DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS_FOR_BACKEND
        : DEFAULT_STATE_FOR_BACKEND;

      const persistentFilter = parseLocalStorageItemIntoJson(
        withLastComment ? ADMIN_PROJECT_ADVANCED_SEARCH : AUDIT_ADVANCED_SEARCH
      );
      if (persistentFilter) {
        // if filter found in local storage on first render, apply it
        setAdvancedSearch({
          statusList: Object.keys(persistentFilter.projectStatus).filter(
            (status) => status !== "all" && persistentFilter.projectStatus[status] === true
          ),
          timeRange: persistentFilter.timeRange,
          orderBy: persistentFilter.orderBy,
        });
      } else {
        setAdvancedSearch(targetDefaultState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const state = {
    selectedStatus,
    projects,
    isLoading,
    certikTeams,
    tenants,
    searchQuery,
    hasNewProject,
    countLoading,
    countByStatusMap,
  };

  const reducers = {
    setSelectedStatus,
    setProjects,
    setIsLoading,
    setCertikTeams,
    setTenants,
    getProjects,
    getProjectCountByStatus,
    setSearchQuery,
    setHasNewProject,
    setAdvancedSearch,
  };

  return <ProjectContext.Provider value={[state, reducers]}>{children}</ProjectContext.Provider>;
}
