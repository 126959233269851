export function useOtherProps(otherProps, defaultClass) {
  const classList = defaultClass ? [defaultClass] : [];
  const {
    className,
    style = {},
    onClick,
    onFocus,
    onBlur,
    onChange,
    onInput,
    onInvalid,
    onReset,
    onSubmit,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onMouseOut,
    onMouseOver,
    onMouseUp,
  } = otherProps;
  if (className) classList.push(className);
  return {
    className: classList.join(" "),
    style,
    onClick,
    onFocus,
    onBlur,
    onChange,
    onInput,
    onInvalid,
    onReset,
    onSubmit,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onMouseOut,
    onMouseOver,
    onMouseUp,
  };
}

export default useOtherProps;
