import React from "react";
import styled from "styled-components";
import { useTheme } from "../ReactHooks";

const StyledBody = styled.div`
  padding-left: 0px;
  padding-right: 0px;
`;

export function DrawerBody({ children, ...props }) {
  const [theme] = useTheme();
  return (
    <StyledBody $split={theme.layout.split} {...props}>
      {children}
    </StyledBody>
  );
}

export default DrawerBody;
