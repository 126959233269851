import { useCallback, useState } from "react";
import { Pagination as AntdPagination } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { COLORS } from "../../const";
import { DotDotDot, ExpandLeft, ExpandRight } from "./Icons";

/**
 * Wrapper of AntD Pagination with custom icons.
 *
 * @param {number} [props.pageSize=10] Default 10
 * @param {number} [props.current=1] Default 1
 * @param {function} [props.onChange] (page, pageSize) => {} Must define onChange if you provide pageSize/current and want it to change
 *
 * @see https://ant.design/components/pagination for full API.
 */
export default function Pagination({ pageSize, current, onChange, ...props }) {
  // Default props
  const [_current, _setCurrent] = useState(current || 1); // default to user defined or 1
  const [_pageSize, _setPageSize] = useState(pageSize || 10); // default to user defined or 10
  const _onChange = (page, pageSize) => {
    _setCurrent(page);
    _setPageSize(pageSize);
  };

  // Callback ref to replace icons & text
  const paginationRef = useCustomizeSizeChanger([pageSize || _pageSize]);

  return (
    <div className="pagination-container" ref={paginationRef}>
      <AntdPagination
        itemRender={itemRender}
        pageSize={pageSize || _pageSize}
        current={current || _current}
        onChange={onChange || _onChange}
        {...props}
      />
    </div>
  );
}

/**
 * Function to pass to AntD's Pagination prop `itemRender`. Customizes the rendering of Pagination buttons.
 * @param {number} page The page that clicking the element would bring you to
 * @param {string} type The button type - 'page' | 'prev' | 'next' | 'jump-next' | 'jump-prev' ('jump-' is for the ... buttons)
 * @param {React.ReactNode} originalElement
 * @returns {React.ReactNode} The element to render
 *
 * @example <caption>Rendering a ... button that jumps forward to page 6:</caption>
 * if (page === 6 && type === "jump-next") {
 *  return <div>Wow!</div>;
 * }
 * return originalElement;
 * @see https://ant.design/components/pagination/#API
 */
export function itemRender(page, type, originalElement) {
  /** determines the rendering for every element of the pagination
   * page: the page that clicking the element brings you to
   * type: the button type - 'page' | 'prev' | 'next' | 'jump-next' | 'jump-prev' ('jump-' is the ... buttons)
   * originalElement: the original React element
   * e.g. currently viewing page 6, so the ... button will advance to page 11; it's itemRender arguments would be:
   * page=11, type='jump-next' */
  if (type === "prev") {
    return (
      <button className="ant-pagination-item-link" type="button" tabIndex={-1}>
        <ExpandLeft />
      </button>
    );
  }
  if (type === "next") {
    return (
      <button className="ant-pagination-item-link" type="button" tabIndex={-1}>
        <ExpandRight />
      </button>
    );
  }
  if ((type === "jump-next") | (type === "jump-prev")) {
    return (
      <a className="ant-pagination-item-link">
        <div className="ant-pagination-item-container">
          {type === "jump-prev" ? (
            <DoubleLeftOutlined className="ant-pagination-item-link-icon" />
          ) : (
            <DoubleRightOutlined className="ant-pagination-item-link-icon" />
          )}
          <span className="ant-pagination-item-ellipsis">
            <DotDotDot size={14} />
          </span>
        </div>
      </a>
    );
  }
  return originalElement;
}

/**
 * Returns the itemRender function for an infinite table's pagination.
 * This can be passed to Pagination's itemRender prop to apply our customizations as well as replace the last number with `...` when more data can be loaded.
 * @param {boolean} hasMore
 * @param {number} pageSize
 * @param {number} dataLength
 * @param {boolean} loading
 * @returns A function to be used in Pagination's itemRender, of type (page, type, originalElement) => React.ReactNode
 */
export function infiniteTableItemRender(hasMore, pageSize, dataLength, dataLoading) {
  const lastPage = Math.ceil(dataLength / pageSize);
  function _infiniteTableItemRender(page, type, originalElement) {
    // the returned itemRender function, which we will use in pagination
    console.log(page);
    console.log(type);
    console.log({ hasMore });
    const ellipsis = ( // same as in itemRender
      <a className="ant-pagination-item-link" title="Load more">
        <div className="ant-pagination-item-container">
          <span className="ant-pagination-item-ellipsis">
            <DotDotDot size={14} color={"currentColor"} />
          </span>
        </div>
      </a>
    );

    const spin = (
      <a className="ant-pagination-item-link">
        <div className="ant-pagination-item-container">
          <span className="ant-pagination-item-ellipsis">
            <LoadingOutlined />
          </span>
        </div>
      </a>
    );

    if (hasMore && type === "page" && page === lastPage) {
      return dataLoading ? <LoadingOutlined /> : ellipsis;
    }

    // otherwise, it's either not the last page or the end of the infinite table has been reached; use regular itemRender rules
    return itemRender(page, type, originalElement);
  }

  return _infiniteTableItemRender;
}

/**
 * Customizes the rows/page selector of a pagination component. Replaces dropdown arrow and text content. 
 *
 * @param {array} dependencies The dependency array, most likely containing pageSize.
 * @returns Callback ref; initialize this to a ref on an HTML element wrapping your pagination component.
 * @example
 * ```
 * const tableRef = useCustomizeSizeChanger([_pagination?.pageSize]);

  return (
    <div className="table-container" ref={tableRef}>
      <Table {...props} />
    </div>
  )
 * ```
 */
export function useCustomizeSizeChanger(dependencies) {
  const [dropDownHasEventListener, setHasEventListener] = useState(false);

  return useCallback(
    (ref) => {
      if (!ref) return;
      const arrowSpan = ref.getElementsByClassName("anticon anticon-down ant-select-suffix")[0];
      const dropDown = ref.getElementsByClassName("ant-pagination-options")[0];
      const dropDownSpan = ref.getElementsByClassName("ant-select-selection-item")[0];
      const dropDownOptions = ref.getElementsByClassName("ant-select-item-option-content");
      const regEx = /(\d+) \/ page/;

      if (dropDown && !dropDownHasEventListener) {
        // set event listener
        dropDown.addEventListener("click", () => {
          if (dropDownOptions?.length) {
            for (let i = 0; i < dropDownOptions.length; i++) {
              const newText = dropDownOptions[i].textContent.replace(regEx, "$1 rows/page");
              dropDownOptions[i].textContent = newText;
            }
          }
        });

        setHasEventListener(true); // set flag so we don't keep adding the same event listener
      }

      if (arrowSpan) {
        let arrowSpanSvg = arrowSpan.firstChild;

        const arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        arrowIcon.setAttribute("width", "10px");
        arrowIcon.setAttribute("height", "10px");
        arrowIcon.setAttribute("viewbox", "0 0 10 5");
        arrowIcon.setAttribute("fill", "none");
        path.setAttribute("d", "M0.833336 0.333313L5 4.49998L9.16667 0.333313H0.833336Z");
        path.setAttribute("fill", `${COLORS["gray-900"]}`);

        arrowIcon.appendChild(path);
        arrowSpan.replaceChild(arrowIcon, arrowSpanSvg);
      }

      if (dropDownSpan) {
        const newText = dropDownSpan.textContent.replace(regEx, "$1 rows/page");
        dropDownSpan.textContent = newText;
      }
    },
    [dropDownHasEventListener, ...dependencies]
  );
}
