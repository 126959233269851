import React from "react";
import styled from "styled-components";
import { COLORS } from "../../const/colors";

const titleStyles = {
  [1]: {
    fontSize: "24px",
    lineHeight: "32px",
    weight: 500,
    marginBottom: 0,
    color: "gray-900",
  },
  [2]: {
    fontSize: "20px",
    lineHeight: "28px",
    weight: 500,
    marginBottom: 0,
    color: "gray-900",
  },
  [3]: {
    fontSize: "16px",
    lineHeight: "24px",
    weight: 600,
    marginBottom: 0,
    color: "gray-900",
  },
  [4]: {
    fontSize: "14px",
    lineHeight: "20px",
    weight: 600,
    marginBottom: 0,
    color: "gray-900",
  },
};

export const StyledHeader = styled.h1`
  font-style: normal;
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  color: ${(props) => props.color};
`;

/**
 * The Header component is used to display a header or a title. The Header component will generally take a `level` prop (1-4) to determine the size of the header.
 * This component should be used for things such a title for a section or headers of a page.
 * The Header component should always be used instead of the `h${x}` tag.
 * Here are some examples of how to use the Header component:
 * ```jsx
 * <Header level={1}>
 *     Page Title Here
 * </Header>
 * ```
 * ```jsx
 * <Header level={2}>
 *     This is a subheading
 * </Header>
 * ```
 * ```jsx
 * <Header level={3}>
 *     This is a minor heading of a small section
 * </Header>
 * ```
 * ```jsx
 * <Header level={4}>
 *     Heading for small tables or small lists
 * </Header>
 * ```
 * ```jsx
 * <Header level={2} color="ck2">
 *     Heading with color overridden
 * </Header>
 * ```
 * @param children
 * @param {Object} props
 * @param {number} props.level Level for the header (1-4). corresponds with h1, h2, h3, and h4.
 * @param {string} props.fontSize This will override the font size.
 * @param {string} props.lineHeight This will override the line height.
 * @param {string} props.weight This will override the font weight.
 * @param {string} props.marginTop This will override the margin top.
 * @param {string} props.marginBottom This will override the margin bottom.
 * @param {string} props.color This will override the color.
 * @returns {JSX.Element} element
 */
export function Header({
  level = 1,
  fontSize,
  lineHeight,
  weight,
  marginTop = 0,
  marginBottom = 0,
  color,
  children,
  ...props
}) {
  if (level > 4 || level < 1) {
    console.error("Header level must be between 1 and 4; defaulting to 1");
    level = 1;
  }
  const as = `h${level}`;
  const {
    fontSize: defaultFontSize,
    lineHeight: defaultLineHeight,
    weight: defaultWeight,
    marginTop: defaultMarginTop,
    marginBottom: defaultMarginBottom,
    color: defaultColor,
  } = titleStyles[level];

  return (
    <StyledHeader
      as={as}
      size={fontSize || defaultFontSize}
      lineHeight={lineHeight || defaultLineHeight}
      weight={weight || defaultWeight}
      marginTop={marginTop || defaultMarginTop}
      marginBottom={marginBottom || defaultMarginBottom}
      color={COLORS[color] || COLORS[defaultColor]}
      {...props}
    >
      {children}
    </StyledHeader>
  );
}
