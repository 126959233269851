import { useMemo, useState } from "react";
import { BUILD_VERSION_CONFIG } from "../../const";
import { getEnvConfig } from "../../utils/frontend/fetchFromApi";
import localStore, { LocalStore } from "../../utils/frontend/localStore";
import { safeParse } from "../../utils/frontend/utils";
import { useStore } from "./useStore";

let timer;
export const useBuildVersion = () => {
  const [store] = useStore();
  const [notifyUser, setnotifyUser] = useState(false);
  // 24 hours
  const time = 24 * 1000 * 3600;
  const start = async (pageFocus) => {
    // init
    localStorage.removeItem(BUILD_VERSION_CONFIG);
    const latestBuildVersion = await getRemoteBuildVersion();
    storeBuildVersionLocally(latestBuildVersion);

    // loop
    timer = setTimeout(async () => {
      await checkLatestBuild(pageFocus);
    }, time);
  };

  const stop = () => {
    clearTimeout(timer);
  };

  async function getRemoteBuildVersion() {
    const { userId, authData } = store?.userInfo;
    if (!userId || !authData) return;
    return await getEnvConfig("BUILD_VERSION", process.env.NEXT_PUBLIC_ENV, userId, authData);
  }

  function storeBuildVersionLocally(build) {
    localStore.set(
      BUILD_VERSION_CONFIG,
      JSON.stringify({
        build,
        timestamp: Date.now(),
      }),
      LocalStore.LOCAL_STORAGE
    );
  }

  async function checkLatestBuild(pageFocus) {
    timer = setTimeout(async () => {
      await checkLatestBuild(pageFocus);
    }, time);

    const localBuildVersionBlob = safeParse(localStore?.get(BUILD_VERSION_CONFIG));

    if (localBuildVersionBlob == null) {
      // should never hit here in the real execution
      return;
    }

    if (Date.now() - localBuildVersionBlob?.timestamp < time) {
      // config exist and not ready to refetch
      return;
    }

    if (!pageFocus.current) {
      // user not focusing on the page
      return;
    }

    const remoteBuildVersion = await getRemoteBuildVersion();

    const localVersion = localBuildVersionBlob?.build?.value?.buildVersion;
    const remoteVersion = remoteBuildVersion?.value?.buildVersion;

    if (localVersion >= 0 && remoteVersion >= 0 && localVersion !== remoteVersion) {
      setnotifyUser(true);
    }
  }

  return { start, stop, notifyUser };
};
