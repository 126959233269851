import styled from "styled-components";
import { useTheme } from "../ReactHooks";
import { Tabs } from "antd";
import { COLORS } from "../../const";

const StyledTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background: ${COLORS["CK2"]};
  }
  .ant-tabs-tab > .ant-tabs-tab-btn {
    color: ${COLORS["gray-600"]};
    font-weight: 600;
    font-size: 13px;
  }
  .ant-tabs-tab-active > .ant-tabs-tab-btn {
    color: #e53935;
    font-weight: 700;
  }
  .ant-tabs-nav {
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: ${(props) => (props.$split ? "0px" : "8px")};
    padding-left: ${(props) => (props.small ? "24px" : "")};
    border-top: 1px ${COLORS["gray-300"]} solid;
    border-bottom: 1px ${COLORS["gray-300"]} solid;
    height: 40px;
  }
`;

export function DrawerTabs({ defaultActiveKey, activeKey, onChange, children, ...props }) {
  const [theme] = useTheme();
  return (
    <StyledTabs
      $split={theme.layout.split}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      onChange={onChange}
      style={{ paddingLeft: "40px", paddingRight: "40px" }}
      {...props}
      tabBarGutter={16}
    >
      {children}
    </StyledTabs>
  );
}

export default DrawerTabs;
