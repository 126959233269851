import {
  AUDIT_STATUS,
  DEFAULT_FINDING_REPORT_TYPE,
  FINDING_DYNAMIC_SEVERITY,
  resolveAuditStatus,
} from "../../const";
import Logger from "../frontend/logger/browser-logger";
import {
  getGroupsToInsertAndReformattedCollabList,
  updateCollaboratorListForMentionWithGroups,
} from "./userGroup";
import CryptoJS from "crypto-js";
import { bech32 } from "bech32";

/**
 * checks if the given email address is valid
 * @param {string} email the given email address to be examined
 * @returns {boolean} the result
 */
export const validateEmailRegex = (email) => {
  try {
    const re =
      /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  } catch (err) {
    console.error(err.message);
    return false;
  }
};

/**
 * checks if the given string contains any upper case letters
 * @param {string} string the given string to be examined
 * @returns {boolean} the result
 */
export const validateUpperCaseLetterExistsRegex = (string) => {
  const re = /^(?=.*[A-Z])/;
  return re.test(string);
};

/**
 * checks if the given string contains any lower case letters
 * @param {string} string the given string to be examined
 * @returns {boolean} the result
 */
export const validateLowerCaseLetterExistsRegex = (string) => {
  const re = /^(?=.*[a-z])/;
  return re.test(string);
};

/**
 * checks if the given string contains any special characters (!@#$%^&*)
 * @param {string} string the given string to be examined
 * @returns {boolean} the result
 */
export const validateSpecialCharacterExistsRegex = (string) => {
  const re = /^(?=.*[!@#$%^&*])/;
  return re.test(string);
};

/**
 * Omit properties from an object
 * @param {string[]} props
 * @param {Object} obj
 * @returns {Object}
 * @example
 * ```js
 * omit(["dogs"], { cats: 2, dogs: 5 }) // { cats: 2 }
 * ```
 */
export function omit(props = [], obj) {
  if (
    obj == null ||
    ["string", "number", "bigint", "boolean", "symbol", "undefined"].includes(typeof obj)
  ) {
    return obj;
  }
  const keys = Object.keys(obj);
  return keys
    .filter((key) => !props.includes(key))
    .reduce((acc, prop) => ({ ...acc, [prop]: obj[prop] }), {});
}

export function findingTally(findingList, reportType = DEFAULT_FINDING_REPORT_TYPE) {
  const findingCountMap = {};

  if (
    !findingList ||
    !findingList.length ||
    !reportType ||
    !(reportType in FINDING_DYNAMIC_SEVERITY)
  )
    return findingCountMap;
  const aliasMap = FINDING_DYNAMIC_SEVERITY[reportType]?.["ALIAS_MAP"];
  const severityMap = FINDING_DYNAMIC_SEVERITY[reportType]?.["SEVERITY_MAP"];
  findingList.forEach((item) => {
    // item.severity might use alias name such as `Critical, Major...` instead of regular all lowercase severity string
    // Here to formalize the severity string according to aliasMap
    if (!item.severity || item.severity in severityMap !== true) {
      if (item.severity && item.severity in aliasMap) item.severity = aliasMap[item.severity];
      else return;
    }
    findingCountMap[item.severity] = (findingCountMap[item.severity] || 0) + 1;
  });
  return findingCountMap;
}

/**
 * conditionally pluralizes a word based on a count
 * @param {string} word the word to pluralize
 * @param {number} count how many of the item there are
 * @param {string?} suffix the suffix to add
 * @param {boolean?} replace replace the whole word
 * @returns
 */
export function pluralize(word, count, suffix = "s", replace = false) {
  return count !== 1 ? (replace ? suffix : word + suffix) : word;
}

/**
 * `capitalize` takes in a string and returns the capitalized string
 * @param {string} word the word to capitalize
 * @returns {string} the capitalized word
 */
export function capitalize(word) {
  return typeof word === "string" ? word.charAt(0).toUpperCase() + word.slice(1) : word; // if word is "", charAt() and slice() will both return ""
}

const range = (start, stop) => [...Array(stop - start)].map((x, i) => start + i);

export const chunk = (n) => (arr) => {
  return range(0, Math.ceil(arr.length / n)).map((_, i) => arr.slice(i * n, i * n + n));
};

/**
 * Wrapper around `Promise.all` for catching errors
 * @param {Promise<any>[]} promiseArray
 * @returns {Promise<any[]>} array of results
 */
export async function promiseAll(promiseArray) {
  return await Promise.all(
    promiseArray.map((promise) =>
      promise instanceof Promise
        ? promise
            .then((result) => ({
              result,
            }))
            .catch((error) => ({
              result: null,
              error: error.message,
            }))
        : {
            result: promise,
          }
    )
  );
}

/**
 * Wrapper around `promiseAll` for logging errors
 * @param {Promise<any>[]} promiseArray
 * @param {any} loggerObj - Logger object e.g. Logger (default) / console
 * @returns {Promise<any[]>}
 */
export async function promiseAllWithErrorLogging(promiseArray, loggerObj = console) {
  const resultArray = await promiseAll(promiseArray);

  // Log promise results with error objects
  resultArray.forEach((resObj) => {
    if (resObj?.error) {
      const errorLog = "Error caught from a promise in promiseAll";
      loggerObj.log(errorLog, resObj, Logger.levels.ERROR);
    }
  });

  return resultArray;
}

/**
 * Encodes special characters in each path of the given url into utf-8 format.
 * Note: can not deal with urls with query string at the moment.
 * @param {string} srcUrl the url to be encoded
 * @returns {string} encoded url
 */
export function encodeHttpUrl(srcUrl) {
  const url = new URL(srcUrl);
  const [, path] = srcUrl.split(url.origin);
  const encodedPathname = path
    .split("/")
    .map((route) => encodeURIComponent(route))
    .join("/");
  return url.origin + encodedPathname;
}

export function mentionListAdapter(collaboratorsList, projectTenantName) {
  // If the current collaborator list contains at least one collaborator with a role that has an associated assignee group and is not pending,
  // 1. Store assignee role group object into `groupsToInsert` to be inserted into reformattedCollaboratorList later on
  // 2. Remove individual users with the role
  let { groupsToInsert, reformattedCollaboratorList } =
    getGroupsToInsertAndReformattedCollabList(collaboratorsList);

  reformattedCollaboratorList = reformattedCollaboratorList.map((collaborator) => {
    if (collaborator.isPending) {
      return {
        id: collaborator.userId,
        value: `${collaborator.userId?.split("-")[0]} (pending user)`,
        role: collaborator?.role,
        userId: collaborator.userId, // For compatibility
      };
    }
    switch (collaborator.role) {
      case "certikBd":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (CertiK BD)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "certikDev":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (CertiK Eng)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "certikSupport":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (CertiK Support)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "certikAdmin":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (CertiK Admin)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "exchangeAdmin":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (${projectTenantName} Admin)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "exchangeMember":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (${projectTenantName} Member)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "exchangeAssignee":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (${projectTenantName} Restricted Member)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
    }
  });

  // If there are groups to insert, place them at the start of the mention list
  return updateCollaboratorListForMentionWithGroups(reformattedCollaboratorList, groupsToInsert);
}

/* Check whether a project status needs to be rolled back when a new final report is published
 * Prerequisite 1: project is final-report-signed-off or completed
 * Prerequisite 2: project requests to publish or already has got a published link
 * */
export function auditStatusRollbackPrerequisite(projectBlob) {
  return (
    [AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF, AUDIT_STATUS.COMPLETED].includes(
      resolveAuditStatus(projectBlob?.status)
    ) && projectBlob?.clientRequestToPublish === true
  );
}

export function encrypt(message) {
  const encrypted = CryptoJS.AES.encrypt(message, process.env.AES_KEY);
  return encrypted.toString();
}

export function decrypt(message) {
  const code = CryptoJS.AES.decrypt(message, process.env.AES_KEY);
  const decryptedMessage = code.toString(CryptoJS.enc.Utf8);

  return decryptedMessage;
}

const CERTIK_CHAIN_ADDRESS_PREFIX = "certik";
/**
 * checks if the given value is a valid certik chain account address
 * @param {string} value the value to be examined
 * @returns {boolean} the boolean result
 */
export function validateCertikChainAddress(value) {
  const re = new RegExp(`^${CERTIK_CHAIN_ADDRESS_PREFIX}[0-9,a-z]{39}$`);
  if (re.test(value) !== true) {
    return false;
  }
  try {
    const decodedBech32 = bech32.decode(value);
    return (
      decodedBech32?.prefix === CERTIK_CHAIN_ADDRESS_PREFIX && decodedBech32?.words?.length === 32
    );
  } catch (err) {
    console.log(err);
    return false;
  }
}

/**
 * Checks if input string follows valid twitter username formatting
 * @param {string} twitterUsername the input string
 * @returns {boolean} true if input string is valid
 */
export function twitterUsernameFormatValidator(twitterUsername) {
  return /^@(\w){4,15}$/.test(twitterUsername);
}

/**
 * Checks if input string follows valid telegram username formatting
 * @param {string} telegramUsername the input string
 * @returns {boolean} true if input string is valid
 */
export function telegramUsernameFormatValidator(telegramUsername) {
  return /^@(\w){5,}$/.test(telegramUsername);
}
