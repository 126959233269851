import PropTypes from "prop-types";
import { FilterIcon } from "../SharedComponents/Icons";
import { Button } from "../SharedComponents/Button";

export function FilterButton({ onClick, disabled, loading, testid, id, style }) {
  return (
    <Button
      type="iconOnly"
      icon={<FilterIcon size={20} />}
      testid={testid}
      id={id}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      style={style}
    />
  );
}

FilterButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
