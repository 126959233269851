import React from "react";
import { COLORS } from "../../const";
import { useTranslation } from "react-i18next";
export function FieldValue({ value, children, defaultText, testId = "" }) {
  const { t } = useTranslation();
  defaultText = defaultText || t("components.SharedComponents.FieldValue.none");
  return (
    <span
      data-testid={testId}
      style={{
        color: value || children ? COLORS["gray-900"] : "#00000080",
        overflowWrap: "break-word",
        fontSize: "13px",
        lineHeight: "20px",
        fontWeight: "500",
      }}
    >
      {value || children || defaultText}
    </span>
  );
}

export default FieldValue;
