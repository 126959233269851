import PropTypes from "prop-types";
import { EditIcon } from "../SharedComponents/Icons";
import { COLORS } from "../../const";
import { Button } from "../SharedComponents/Button";

export function EditButton({ onClick, disabled, loading, testid }) {
  return (
    <Button
      type="iconOnly"
      icon={<EditIcon size={20} />}
      testid={testid}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    />
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
