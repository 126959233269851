import React from "react";
import { Flex } from "./Flex";
import { Box } from "./Box";
import { Paragraph } from "./index";
import { COLORS, CROSS_SALE_TYPE } from "../../const";
import { ExpandRight } from "./Icons";
import { useUserAction } from "../ReactHooks/useWorker";
import { Header } from "./Header";

export function CrossSaleItem({
  title,
  description,
  icon,
  href,
  type,
  projectInfo,
  onClickAction,
}) {
  const [, collect] = useUserAction();
  return (
    <Flex
      justifyContent="space-between"
      alignItems="flex-start"
      padding="8px 0px 16px 0px"
      onClick={() => {
        console.log("cross sale clicked");
        let meta = { projectId: projectInfo?.id };
        if (type === CROSS_SALE_TYPE.twitter) {
          meta.publishedLink = projectInfo?.publishedLink;
        }
        if (type === CROSS_SALE_TYPE.publish) {
          meta.reportFileName = projectInfo?.auditReportList[0]?.fileName;
        }
        collect("audit drawer cross sale", `click ${type} button`, meta, {
          projectId: projectInfo?.id,
        });
        if (href != null) {
          if (type === CROSS_SALE_TYPE.twitter) {
            window.open(href, "_blank");
          } else {
            window.location.href = href;
          }
        } else if (onClickAction != null) {
          onClickAction();
        }
      }}
      style={{ cursor: "pointer", boxShadow: `inset 0px -1px 0px ${COLORS["gray-300"]}` }}
    >
      <Flex>
        <Box
          width="32px"
          height="32px"
          mt={3}
          mr="16px"
          style={{
            backgroundColor: "none",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "32px",
            minHeight: "32px",
          }}
        >
          {icon}
        </Box>
        <Flex flexDirection="column" justifyContent="center">
          <Header level={4} color="gray-900" weight={600} marginBottom={0}>
            {title}
          </Header>
          <Paragraph level={2} color={COLORS["gray-700"]} weight={400} marginBottom={0}>
            {description}
          </Paragraph>
        </Flex>
      </Flex>
      <div style={{ paddingTop: 4 }}>
        <ExpandRight size={24} color={COLORS["gray-900"]} />
      </div>
    </Flex>
  );
}
