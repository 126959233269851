import { Button, Row, Tooltip } from "antd";
import { useState } from "react";
import { verifyContract } from "../../utils/frontend/fetchFromApi";
import {
  LoadingOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { COLORS } from "../../const";
import { useTranslation } from "react-i18next";

function checkValidAddressUrl(url) {
  const regex = /^https:\/\/(etherscan\.io|bscscan\.com)\/address\/0x[0-9a-zA-Z]{40}$/gm;
  return url && !!url.match(regex);
}

export function VerifyAddressButton({ getAddressUrl, onVerify }) {
  const { t } = useTranslation("", { keyPrefix: "components.Buttons.VerifyAddressButton" });

  const [isVerified, setIsVerified] = useState(null);
  const [verifyingContract, setVerifyingContract] = useState(false);
  const [verifyTooltipVisible, setVerifyTooltipVisible] = useState(false);

  const getContractVerified = (addressUrl) => {
    async function verify() {
      setVerifyingContract(true);
      try {
        const res = await verifyContract(addressUrl);
        const isUrlValid = checkValidAddressUrl(addressUrl);
        if (res && isUrlValid) {
          setIsVerified(true);
          onVerify && onVerify(true);
          setVerifyingContract(false);
        } else {
          throw new Error("couldn't verify contract");
        }
      } catch (error) {
        setIsVerified(false);
        onVerify && onVerify(false);
        setVerifyingContract(false);
        console.error(error);
      }
    }
    verify().catch(console.error);
  };

  const tooltipTitle = isVerified ? t("verifiedTip") : t("unverifiedTip");

  const verifyButton = (
    <Button
      data-testid="verify-button"
      type="link"
      style={{ padding: 0 }}
      onClick={() => getContractVerified(getAddressUrl())}
      onMouseEnter={() => setVerifyTooltipVisible(true)}
      onMouseLeave={() => setVerifyTooltipVisible(false)}
    >
      <Row
        align="middle"
        style={{
          marginLeft: "4px",
          color:
            isVerified === null
              ? COLORS["blue-6"]
              : isVerified
              ? COLORS["green-7"]
              : COLORS["red-5"],
        }}
      >
        {verifyingContract ? (
          <LoadingOutlined />
        ) : isVerified === null ? (
          <InfoCircleOutlined />
        ) : isVerified ? (
          <CheckCircleOutlined />
        ) : (
          <CloseCircleOutlined />
        )}
        <span
          style={{
            marginLeft: "4px",
            color:
              isVerified === null
                ? COLORS["blue-6"]
                : isVerified
                ? COLORS["green-7"]
                : COLORS["red-5"],
          }}
        >
          {isVerified === null ? t("validateURL") : isVerified ? t("verified") : t("unverified")}
        </span>
      </Row>
    </Button>
  );

  const displayTooltip = !verifyingContract && isVerified !== null;
  return displayTooltip ? (
    <Tooltip title={tooltipTitle} placement="topRight" visible={verifyTooltipVisible}>
      {verifyButton}
    </Tooltip>
  ) : (
    verifyButton
  );
}
