import React, { createContext, useContext, useState, useEffect } from "react";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import {
  AUTH_DATA,
  USER_ID,
  SELECTED_TENANT_ID,
  IN_APP_NOTIFICATION_TYPES,
  IN_APP_NOTIFICATION_FEATURE_MAP,
} from "../../const";
import { AVAILABLE_LOCALES } from "../../const/locale";
import localStore from "../../utils/frontend/localStore";
import {
  fetchUser,
  getAllPublicConfigs,
  getNotificationList,
} from "../../utils/frontend/fetchFromApi";
import { stdLogout, checkIfFeatureEnabled } from "../../utils/frontend/utils";
import router from "next/router";
import { useTranslation } from "react-i18next";

import { loadIntercom, shutdownIntercom } from "next-intercom";

const StoreContext = createContext([{}, () => {}, () => {}]);

export function useStore() {
  return useContext(StoreContext);
}

export const defaultStore = {
  loggedIn: false,
  userInfo: null,
  drawerProjectId: null,
  // collapse sub sections by default
  // sideBarOpenKeys: ["2", "3", "7", "9"],
  sideBarOpenKeys: [],
  lastSidebarOpenKeys: [],
  isSidebarExtended: true,
  configs: {},
};

const intercomEngine = (store) => {
  const flag = checkIfFeatureEnabled(store, "intercom");
  if (flag === true) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadIntercom({
      appId: "xws919ti", // TODO: move to config
      email: store?.userInfo?.userId,
      name: store?.userInfo?.userName,
      ssr: false, // default: false
      initWindow: true,
      delay: 0, // default: 0  - usefull for mobile devices to prevent blocking the main thread
    });
  } else {
    // For now this func works fine even if intercome has not be inited before
    // to be monitored
    shutdownIntercom();
  }
};

export function WithStore({ children }) {
  const [store, setStore] = useState(defaultStore);
  const [reloadCounter, setReloadCounter] = useState(0);
  const refreshStore = () => setReloadCounter((prev) => prev + 1);
  const { i18n } = useTranslation();
  let currentLangPack = enUS;
  useEffect(() => {
    getAllPublicConfigs()
      .then((resp) =>
        setStore((prev) => ({
          ...prev,
          configs: resp,
        }))
      )
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!store.userInfo) return; // Don't rerun effect after user logout clears store.userInfo
    intercomEngine(store);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.userInfo]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userId = localStore.get(USER_ID);
      const authData = localStore.get(AUTH_DATA);
      if (userId && authData) {
        const userBlob = await fetchUser(userId, JSON.parse(authData), () =>
          stdLogout(setStore, () => router.push("/login"))
        );
        if (!userBlob || !userBlob.id) {
          console.log(userBlob);
          return;
        }
        setStore((prev) => {
          if (prev.userInfo?.authData?.linkId) {
            // in shared pages
            return prev;
          }

          // Setup the preferred language
          const language = userBlob.preferredLanguage;
          if (language !== undefined && language !== null && language in AVAILABLE_LOCALES) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            i18n.changeLanguage(language);
            if (language == "zh-cn") {
              currentLangPack = zhCN;
            } else {
              currentLangPack = enUS;
            }
          }

          // server must return a tenant list
          let currentTenantId = localStore.get(SELECTED_TENANT_ID);
          if (!userBlob.tenantList.map((x) => x.id).includes(currentTenantId)) {
            currentTenantId = userBlob.tenantList[0]?.id;
            localStore.set(SELECTED_TENANT_ID, currentTenantId);
          }

          return {
            ...prev,
            userInfo: {
              userId: userBlob.id,
              avatarUrl: userBlob.avatarUrl,
              userName: userBlob.userName,
              authData: JSON.parse(authData),
              tenantId: userBlob.tenantId,
              tenantName: userBlob.tenantName,
              tenantList: [...(userBlob?.tenantList || [])],
              selectedTenantId: currentTenantId,
              role: userBlob.role,
              emailNotificationDisabled: userBlob.emailNotificationDisabled,
              userConfig: userBlob.userConfig,
              version: userBlob.version,
              theme: userBlob.theme,
              preferredLanguage: language,
              currentLangPack: currentLangPack,
              certikTeamId: userBlob.certikTeamId,
              slackNotificationDisabled: userBlob.slackNotificationDisabled,
              department: userBlob.department,
              jobTitle: userBlob.jobTitle,
            },
            isDemoAccount: ["certik-test"].includes(userBlob.tenantId),
          };
        });
      }
    };
    const isShareReport = router.pathname.includes("shared-report");
    !isShareReport && fetchUserInfo();
  }, [store.loggedIn, reloadCounter]);

  const INIT_LIMIT = Number.MAX_VALUE;

  const fetchNotifications = async (type, limit) => {
    if (store.userInfo == null) {
      return;
    }

    let fetchedList = await getNotificationList(
      store?.userInfo?.userId,
      store?.userInfo?.authData,
      type,
      limit,
      null
    );

    return (fetchedList || []).length;
  };

  const initializeNotifications = async () => {
    const enabledFeatures = store?.userInfo?.userConfig?.enabledFeatures || [];
    const enabledNotificationTypes = Object.values(IN_APP_NOTIFICATION_TYPES).filter((type) => {
      return (
        IN_APP_NOTIFICATION_FEATURE_MAP[type] == null ||
        enabledFeatures.includes(IN_APP_NOTIFICATION_FEATURE_MAP[type])
      );
    });

    let notificationCount = await Promise.all(
      Object.values(enabledNotificationTypes).map(async (type) => {
        return await fetchNotifications(type, INIT_LIMIT);
      })
    );
    notificationCount = notificationCount.reduce((a, b) => a + b, 0);

    setStore((prev) => ({
      ...prev,
      notificationCount,
    }));
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    const isShareReport = router.pathname.includes("shared-report");
    store?.userInfo && !isShareReport && initializeNotifications();
  }, [store.userInfo]);

  return (
    <StoreContext.Provider value={[store, setStore, { refreshStore }]}>
      <ConfigProvider locale={store?.userInfo ? store?.userInfo?.currentLangPack : enUS}>
        {children}
      </ConfigProvider>
    </StoreContext.Provider>
  );
}
