export const AUTH_TYPE = {
  USER: "user",
  TENANT: "tenant",
  EVENT: "event",
};

export const APP_TYPE = {
  SLACK: "slack",
  DISCORD: "discord",
  TELEGRAM: "telegram",
  EMAIL: "email",
};

export const APP_TYPE_LABEL_MAP = {
  [APP_TYPE.SLACK]: "Slack",
  [APP_TYPE.DISCORD]: "Discord",
  [APP_TYPE.TELEGRAM]: "Telegram",
};

export const SKYHARBOR_BOT_STATE_PREFIX = "shb-";
export const SKYHARBOR_BOT_STATE_MAX_AGE_IN_SECONDS = 24 * 60 * 60; // 1d
