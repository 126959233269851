import styled from "styled-components";
import { Input as AntdInput } from "antd";
import { COLORS } from "../../const";

export const NumericInput = (props) => {
  const { value, onChange } = props;

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;

    if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
      onChange(inputValue);
    }
  }; // '.' at the end or only '-' in the input box.

  const placeholder = props.placeholder || "Input a number";

  return <AntdInput {...props} onChange={handleChange} placeholder={placeholder} maxLength={25} />;
};

export const Input = styled(AntdInput)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 6px 12px;
  height: 32px;
  color: ${COLORS["gray-900"]};
`;

export const TextArea = styled(AntdInput.TextArea)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 12px;
  color: ${COLORS["gray-900"]};
  ${(props) => props.minHeight && `min-height: ${props.minHeight} !important;`}
`;
