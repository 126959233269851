import { Button as AntdButton } from "antd";
import styled from "styled-components";
import { Space } from "./Space";
import React from "react";

const StyledButton = styled(AntdButton)`
  /* Primary buttons (type="primary") */
  &.ant-btn-primary {
    border-radius: 4px;
    background: var(--ck2);
    border-color: var(--ck2);
    text-shadow: none;
  }

  &.ant-btn-primary div.ant-space-item {
    height: 20px;
    line-height: 20px;
  }

  &.ant-btn-primary:hover,
  &.ant-btn-primary:focus {
    background: var(--red-400);
    border-color: var(--red-400);
  }

  &.ant-btn-primary:active {
    background: var(--red-700);
    border-color: var(--red-700);
  }

  &.ant-btn-primary[disabled] {
    border-radius: 4px;
    background: var(--gray-300);
    border-color: var(--gray-300);
    text-shadow: none;
    color: var(--gray-600);
  }

  /* Secondary buttons (type="secondary") */
  &.ant-btn-secondary {
    border-radius: 4px;
    border-color: var(--gray-400);
    text-shadow: none;
    color: var(--gray-900);
  }

  &.ant-btn-secondary div.ant-space-item {
    height: 20px;
    line-height: 20px;
  }

  &.ant-btn-secondary:hover,
  &.ant-btn-secondary:focus {
    border-color: var(--red-400);
    color: var(--red-400);
  }

  &.ant-btn-secondary:active {
    background: var(--red-700);
    border-color: var(--red-700);
  }

  // Disabled secondary button styles - it's long because a CSS rule in antd.css was overriding it, so I appended that rule to make sure it doesn't (the .ant-btn:not stuff).
  &.ant-btn-secondary.ant-btn:not(.ant-btn-primary):not(.ant-btn-link):not(.ant-btn-text):not(.ant-dropdown-trigger):not(.ant-btn-iconOnly)[disabled] {
    border-radius: 4px;
    background: var(--gray-300);
    border-color: var(--gray-300) !important;
    text-shadow: none;
    color: var(--gray-600) !important;
  }

  /* IconOnly buttons (type="iconOnly") */
  &.ant-btn-iconOnly {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    padding: 4px;
    border-color: var(--gray-400);
  }

  &.ant-btn-iconOnly:hover,
  &.ant-btn-iconOnly:focus {
    border-color: var(--red-400);
    color: var(--red-400);
  }

  &.ant-btn-iconOnly[disabled] {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    padding: 4px;
    background: var(--gray-300);
    border-color: var(--gray-300);
    color: var(--gray-600);
  }

  /* Link buttons (type="link") */
  &.ant-btn-link {
    width: 62px;
    height: 32px;
    border-radius: 2px;
    padding: 4px 0px 6px 0px;
    gap: 4px;
    color: var(--gray-900);
  }

  &.ant-btn-link:hover,
  &.ant-btn-link:focus {
    color: var(--ck2);
  }
`;

/**
 * Button component of UI spec
 * @param {ReactNode} [prefix] - The component on the left of label, (usually an icon)
 * @param {ReactNode} [suffix] - The component on the right of label, (usually an icon)
 * @param {string} [label] - 	The text shows on the button
 * @param {string} [type="primary"] - Identify the type of this button ["primary","secondary","iconOnly","link"] ("primary is the default value")
 * @param {object} [props]
 * @param {ReactNode} [props.icon] - The icon of this button (Only use with iconOnly type)
 * @param {ReactNode} [props.children] - The component will be used instead of label
 * @example
 * ```jsx
 *  <Button type="primary" label="Button" prefix={<PlusIcon size="20px" />} suffix={<PlusIcon size="20px" />} /> // a primary button with prefix and suffix icon
 *  <Button type="secondary" label="Button" /> // a secondary button only has label
 *  <Button type="iconOnly" icon={<RefreshIcon size="20px" />} /> // a button with only icon
 *  <Button type="link" label={"Reset"} suffix={<LaunchIcon size="1em" />} /> // a link button, icon is the same size of the text
 *  <Button type="primary" label="Button" disabled /> // a primary being disabled
 *  <Button label="Default Button" /> // a primary button
 * ```
 */
export function Button({ prefix, suffix, label, type = "primary", ...props }) {
  return (
    <StyledButton type={type} {...props}>
      {!props.icon && (
        <Space direction="horizontal" size={4}>
          {prefix}
          {label || props.children}
          {suffix}
        </Space>
      )}
    </StyledButton>
  );
}
