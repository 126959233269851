export const INDEX_WHITELIST = ["projectId", "tenantId"];

export const ACTION_OPTIONS = {
  accelerator: {
    PUBLISH_REPORT: "publish report",
  },
  "audit drawer cross sale": {
    PUBLISH_REPORT: "click publish button",
  },
  "audit drawer overview": {
    FILL_ACCOUNT_INFO: "click fill account info button",
    SIGN_OFF: "perform sign off action",
    CLICK_ENABLED: "click emblem enabled checkbox in project detail tab",
  },
  "audit report": {
    SHARE: "click Share button",
    SHARE_2: "click share button",
    DOWNLOAD_PDF: "click download pdf button",
    EXPORT_COMMENTS: "click export comments button",
    NOTIFY_CERTIK: "click notify certik button",
  },
  organization: {
    INVITE_USER: "inviter invite new user",
    TOGGLE_FEATURE: "toggle enabled feature",
    TOGGLE_ALL_FEATURES: "toggle enable all features",
    UPDATE_TENANT_DETAIL: "click save edit button in organization tab",
    CREATE_API_KEY: "click create in add api key modal",
    EDIT_API_KEY: "click save in edit api key modal",
    DELETE_API_KEY: "click delete api key in api keys tab",
  },
  signup: {
    SIGNUP_RESPONSE: "signup response",
  },
};

export const PROJECT_ACTIONS_WHITELIST = {
  accelerator: [ACTION_OPTIONS["accelerator"].PUBLISH_REPORT],
  "audit drawer cross sale": [ACTION_OPTIONS["audit drawer cross sale"].PUBLISH_REPORT],
  "audit drawer overview": [
    ACTION_OPTIONS["audit drawer overview"].FILL_ACCOUNT_INFO,
    ACTION_OPTIONS["audit drawer overview"].SIGN_OFF,
    ACTION_OPTIONS["audit drawer overview"].CLICK_ENABLED,
  ],
  "audit report": [
    ACTION_OPTIONS["audit report"].SHARE,
    ACTION_OPTIONS["audit report"].SHARE_2,
    ACTION_OPTIONS["audit report"].DOWNLOAD_PDF,
    ACTION_OPTIONS["audit report"].EXPORT_COMMENTS,
    ACTION_OPTIONS["audit report"].NOTIFY_CERTIK,
  ],
  organization: [ACTION_OPTIONS["organization"].INVITE_USER],
  signup: [ACTION_OPTIONS["signup"].SIGNUP_RESPONSE],
};

export const TENANT_ACTIONS_WHITELIST = {
  organization: [
    ACTION_OPTIONS["organization"].INVITE_USER,
    ACTION_OPTIONS["organization"].TOGGLE_FEATURE,
    ACTION_OPTIONS["organization"].TOGGLE_ALL_FEATURES,
    ACTION_OPTIONS["organization"].UPDATE_TENANT_DETAIL,
    ACTION_OPTIONS["organization"].CREATE_API_KEY,
    ACTION_OPTIONS["organization"].EDIT_API_KEY,
    ACTION_OPTIONS["organization"].DELETE_API_KEY,
  ],
};
