import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
export const StyledViewMoreButton = styled(Button)`
  background-color: var(--gray-100);
  border: none;
  box-shadow: none;
  border-radius: 0px;
  color: var(--gray-900) !important;
  font-size: 13px;
  font-weight: 500;

  &:hover {
    background-color: var(--gray-200);
    border: none;
  }

  &:active,
  &:focus {
    background-color: var(--gray-300);
  }

  margin-bottom: 4px;
  height: 30px;
`;

export const ViewMoreButton = (props) => {
  const { t } = useTranslation("", {
    keyPrefix: "components.SharedComponents.Buttons.ViewMoreButton",
  });
  const defaultChildrenLabel = props.children || t("viewMore");
  return <StyledViewMoreButton {...props}>{defaultChildrenLabel}</StyledViewMoreButton>;
};
