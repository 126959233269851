/**
 * checks if the supplied keys exist on `obj`
 * @param {any} obj
 * @param {string[]} keys
 * @returns boolean
 * @example
 * ```js
 * const a = { b: 1, c: 2 }
 * hasProp(a, "b") // true
 * hasProp(a, "b", "c") // true
 * hasProp(a, "b", "c", "d") // false
 * ```
 */
export function hasProp(obj, ...keys) {
  if (obj == null || keys.length === 0) {
    return false;
  }
  return keys.every((key) => Object.prototype.hasOwnProperty.call(obj, key));
}

/**
 * filters an object with a function
 * @param {Object} obj object to filter
 * @param {(value: any, key: string | number | symbol, obj: Object)} filter filter function, same syntax as Array.filter
 * @returns {Object} filtered object
 */
export const filterObject = (obj, filter) => {
  if (!filter || !obj) {
    return obj;
  }
  return Object.keys(obj).reduce((acc, key) => {
    return filter(obj[key], key, obj)
      ? {
          ...acc,
          [key]: obj[key],
        }
      : acc;
  }, {});
};

/**
 * filters nullish values from an object (shallow)
 * @param {Object} obj object to filter
 * @returns {Object} filtered object
 */
export const filterNullish = (obj) => filterObject(obj, (val) => val != null);

/**
 * filters undefined values from an object recursively (deep)
 * @param {Object} obj object to filter
 * @returns {Object} filtered object
 */
export const removeUndefinedValueForObject = (obj) => {
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(removeUndefinedValueForObject).filter((item) => item !== undefined);
    }
    return Object.fromEntries(
      Object.entries(obj)
        .map(([key, val]) => [key, removeUndefinedValueForObject(val)])
        .filter(([, v]) => v !== undefined)
    );
  }
  return obj;
};
