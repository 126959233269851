/* This file is used via soft links in several aws lambdas.
 * Keep this file and its dependencies in common JS style.
 * When introducing new dependencies to this file, soft-link them to aws lambdas as well.
 */

export const ALERT_CHART_TIME_WINDOW_OPTIONS = [
  // { label: "24 hours", value: 1 },
  { label: "Last 7 days", value: 7 },
  { label: "Last 30 days", value: 30 },
  { label: "Last 90 days", value: 90 },
];

export const ALERT_TEMPLATE_ENUM = {
  ROLE_CHANGES: "roleChanges",
  PRICE_ALERT: "priceAlert",
  HACK_SCAN_ALERT: "hackScanAlert",
  WHITELISTED_CALLERS: "whitelistedCallers",
  BLACKLISTED_CALLERS: "blacklistedCallers",
  SUCCEEDED_TRANSACTION: "succeededTransaction",
  FAILED_TRANSACTION: "failedTransaction",
  TRANSACTION_VALUE: "transactionValue",
  EVENT_EMITTED: "eventEmitted",
  EVENT_PARAMETER: "eventParameter",
  ERC20_TOKEN_TRANSFER: "erc20TokenTransfer",
  TOKEN_BUY_OR_SELL: "tokenBuyOrSell",
  TOKEN_VOLUME: "tokenVolume",
  HACKER_ADDRESS: "hackerAddress",
  NFT_ALERT: "nftAlert",
  NFT_COLLECTION_ALERT: "nftCollectionAlert",
  HACK_EVENT_ALERT: "hackEventAlert",
  FLASHLOAN_ALERT: "flashloanAlert",
  PRICE_SLIPPAGE_ALERT: "priceSlippageAlert",
};

export const ALERT_NAME_MAP = {
  roleChanges: "Role Change",
  priceAlert: "Price Alert",
  hackScanAlert: "Hack Scan Alert",
  whitelistedCallers: "Whitelisted Callers Alert",
  blacklistedCallers: "Blacklisted Callers Alert",
  succeededTransaction: "Succeeded Transaction Alert",
  failedTransaction: "Failed Transaction Alert",
  transactionValue: "Transaction Value Alert",
  eventEmitted: "Event Emitted Alert",
  eventParameter: "Event Parameter Alert",
  erc20TokenTransfer: "ERC20 Token Transfer Alert",
  tokenBuyOrSell: "Token Buy Or Sell Alert",
  tokenVolume: "Token Volume Alert",
  hackerAddress: "Hacker Address Alert",
  hackEventAlert: "Hack Event Alert",
  flashloanAlert: "Flashloan Alert",
  priceSlippageAlert: "Price Slippage Alert",
};

export const ALERT_GROUPS_ENUM = {
  SECURITY: "security",
  TRANSACTIONS: "transactions",
  EVENTS: "events",
  VALUE_CHANGE: "value-change",
  HACKER: "hacker",
  NFT_ALERT: "nftAlert",
  NFT_COLLECTION_ALERT: "nftCollectionAlert",
  HACK_EVENT: "hackEvent",
  FLASHLOAN: "flashloanAlert",
  PRICE_SLIPPAGE: "priceSlippageAlert",
};

export const ROLE_CHANGE_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.SECURITY,
  id: ALERT_TEMPLATE_ENUM.ROLE_CHANGES,
  name: "Role Changes",
  description: "Get notified when important roles of a smart contract are changed.",
  whitelist: [],
};
export const WHITELISTED_CALLERS_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.SECURITY,
  id: ALERT_TEMPLATE_ENUM.WHITELISTED_CALLERS,
  name: "Whitelisted Callers",
  description: "Get notified when a smart contract is called by a whitelisted caller.",
  whitelist: [],
};
export const BLACKLISTED_CALLERS_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.SECURITY,
  id: ALERT_TEMPLATE_ENUM.BLACKLISTED_CALLERS,
  name: "Blacklisted Callers",
  description: "Get notified when a smart contract is called by a blacklisted caller.",
  whitelist: [],
};
export const SUCCEEDED_TRANSACTION_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.TRANSACTIONS,
  id: ALERT_TEMPLATE_ENUM.SUCCEEDED_TRANSACTION,
  name: "Succeeded Transaction",
  description: "Get notified when a smart contract has a successful transaction execution.",
  whitelist: [],
};
export const FAILED_TRANSACTION_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.TRANSACTIONS,
  id: ALERT_TEMPLATE_ENUM.FAILED_TRANSACTION,
  name: "Failed Transaction",
  description: "Get notified when a smart contract has an unsuccessful transaction execution.",
  whitelist: [],
};
export const TRANSACTION_VALUE_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.TRANSACTIONS,
  id: ALERT_TEMPLATE_ENUM.TRANSACTION_VALUE,
  name: "Transaction Value",
  description: "Get notified when the transaction value of a smart contract is changed.",
  whitelist: [],
};
export const EVENT_EMITTED_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.EVENTS,
  id: ALERT_TEMPLATE_ENUM.EVENT_EMITTED,
  name: "Event Emitted",
  description: "Get notified when an event of a smart contract is emitted.",
  whitelist: [],
};
export const EVENT_PARAMETER_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.EVENTS,
  id: ALERT_TEMPLATE_ENUM.EVENT_PARAMETER,
  name: "Event Parameter",
  description: "Get notified when an event parameter of a smart contract is changed.",
  whitelist: [],
};
export const ERC20_TOKEN_TRANSFER_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.EVENTS,
  id: ALERT_TEMPLATE_ENUM.ERC20_TOKEN_TRANSFER,
  name: "ERC20 Token Transfer",
  description: "Get notified when an ERC20 token is transferred.",
  whitelist: [],
};

export const PRICE_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.VALUE_CHANGE,
  id: ALERT_TEMPLATE_ENUM.PRICE_ALERT,
  name: "Price Alert",
  description: "Get notified when a token goes above or below a price target.",
  whitelist: [
    "coinGeckoLink",
    "coinMarketCapLink",
    "priceUpperBound",
    "priceLowerBound",
    "tokenTicker",
  ],
};
export const TOKEN_BUY_OR_SELL_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.VALUE_CHANGE,
  id: ALERT_TEMPLATE_ENUM.TOKEN_BUY_OR_SELL,
  name: "Token Buy Or Sell",
  description: "Get notified when a token is bought or sold.",
  whitelist: [],
};
export const TOKEN_VOLUME_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.VALUE_CHANGE,
  id: ALERT_TEMPLATE_ENUM.TOKEN_VOLUME,
  name: "Token Volume",
  description: "Get notified when a target token volume is reached.",
  whitelist: [],
};
export const HACKER_ADDRESS_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.HACKER,
  id: ALERT_TEMPLATE_ENUM.HACKER_ADDRESS,
  name: "Hacker Address",
  description: "Get notified when a hacker address is detected.",
  whitelist: [],
};

export const HACK_SCAN_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.VALUE_CHANGE,
  id: ALERT_TEMPLATE_ENUM.HACK_SCAN_ALERT,
  name: "Hack Scan Alert",
  description: "Get notified when the Hack Scan score of a project breaks assertion.",
  whitelist: [
    "scoreUpperBoundEnabled",
    "scoreUpperBound",
    "scoreLowerBoundEnabled",
    "scoreLowerBound",
  ],
};

export const HACK_NFT_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.NFT_ALERT,
  id: ALERT_TEMPLATE_ENUM.NFT_ALERT,
  name: "NFT Alert",
  description: "Get notified when the nft score breaks assertion.",
  whitelist: [
    "scoreUpperBoundEnabled",
    "scoreUpperBound",
    "scoreLowerBoundEnabled",
    "scoreLowerBound",
  ],
};

export const HACK_EVENT_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.HACK_EVENT,
  id: ALERT_TEMPLATE_ENUM.HACK_EVENT_ALERT,
  name: "Hack Event Alert",
  description: "Get notified when hack events happen",
  whitelist: [],
};

export const HACK_NFT_COLLECTION_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.NFT_COLLECTION_ALERT,
  id: ALERT_TEMPLATE_ENUM.NFT_COLLECTION_ALERT,
  name: "NFT Collection Alert",
  description: "Get notified when the nft event happen",
};
export const FLASHLOAN_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.FLASHLOAN,
  id: ALERT_TEMPLATE_ENUM.FLASHLOAN_ALERT,
  name: "Flashloan Alert",
  description: "Get notified when flashloan alerts happen",
  whitelist: [],
};

export const PRICE_SLIPPAGE_ALERT_TEMPLATE = {
  group: ALERT_GROUPS_ENUM.PRICE_SLIPPAGE,
  id: ALERT_TEMPLATE_ENUM.PRICE_SLIPPAGE_ALERT,
  name: "Price Slippage Alert",
  description: "Get notified when price slippage alerts happen",
  whitelist: [],
};

//for use in alertSubscriptionWorker
export const ALERT_SUBSCRIPTION_EVENTS = [
  HACK_EVENT_ALERT_TEMPLATE.id,
  FLASHLOAN_ALERT_TEMPLATE.id,
  PRICE_SLIPPAGE_ALERT_TEMPLATE.id,
  HACK_NFT_COLLECTION_ALERT_TEMPLATE.id,
];

export const ALERT_TEMPLATE_MAP = new Map([
  [ALERT_TEMPLATE_ENUM.ROLE_CHANGES, ROLE_CHANGE_ALERT_TEMPLATE],
  [ALERT_TEMPLATE_ENUM.PRICE_ALERT, PRICE_ALERT_TEMPLATE],
  [ALERT_TEMPLATE_ENUM.HACK_SCAN_ALERT, HACK_SCAN_ALERT_TEMPLATE],
  [ALERT_TEMPLATE_ENUM.NFT_ALERT, HACK_NFT_ALERT_TEMPLATE],
  [ALERT_TEMPLATE_ENUM.HACK_EVENT_ALERT, HACK_EVENT_ALERT_TEMPLATE],
  [ALERT_TEMPLATE_ENUM.NFT_COLLECTION_ALERT, HACK_NFT_COLLECTION_ALERT_TEMPLATE],
  [ALERT_TEMPLATE_ENUM.FLASHLOAN_ALERT, FLASHLOAN_ALERT_TEMPLATE],
  [ALERT_TEMPLATE_ENUM.PRICE_SLIPPAGE_ALERT, PRICE_SLIPPAGE_ALERT_TEMPLATE],
]);

export const ALERT_TEMPLATES = {
  [ALERT_GROUPS_ENUM.SECURITY]: {
    id: ALERT_GROUPS_ENUM.SECURITY,
    name: "Security",
    templates: [
      WHITELISTED_CALLERS_ALERT_TEMPLATE,
      BLACKLISTED_CALLERS_ALERT_TEMPLATE,
      ROLE_CHANGE_ALERT_TEMPLATE,
    ],
  },
  [ALERT_GROUPS_ENUM.TRANSACTIONS]: {
    id: ALERT_GROUPS_ENUM.TRANSACTIONS,
    name: "Transactions",
    templates: [
      SUCCEEDED_TRANSACTION_ALERT_TEMPLATE,
      FAILED_TRANSACTION_ALERT_TEMPLATE,
      TRANSACTION_VALUE_ALERT_TEMPLATE,
    ],
  },
  [ALERT_GROUPS_ENUM.EVENTS]: {
    id: ALERT_GROUPS_ENUM.EVENTS,
    name: "Events",
    templates: [
      EVENT_EMITTED_ALERT_TEMPLATE,
      EVENT_PARAMETER_ALERT_TEMPLATE,
      ERC20_TOKEN_TRANSFER_ALERT_TEMPLATE,
    ],
  },
  [ALERT_GROUPS_ENUM.VALUE_CHANGE]: {
    id: ALERT_GROUPS_ENUM.VALUE_CHANGE,
    name: "Value Change",
    templates: [
      PRICE_ALERT_TEMPLATE,
      TOKEN_BUY_OR_SELL_ALERT_TEMPLATE,
      TOKEN_VOLUME_ALERT_TEMPLATE,
    ],
  },
  [ALERT_GROUPS_ENUM.NFT_ALERT]: {
    id: ALERT_GROUPS_ENUM.NFT_ALERT,
    name: "NFT Alerts",
    templates: [
      PRICE_ALERT_TEMPLATE,
      TOKEN_BUY_OR_SELL_ALERT_TEMPLATE,
      TOKEN_VOLUME_ALERT_TEMPLATE,
    ],
  },
  [ALERT_GROUPS_ENUM.NFT_COLLECTION_ALERT]: {
    id: ALERT_GROUPS_ENUM.NFT_COLLECTION_ALERT,
    name: "NFT Collection Alerts",
    templates: [HACK_NFT_COLLECTION_ALERT_TEMPLATE],
  },
  [ALERT_GROUPS_ENUM.HACKER]: {
    id: ALERT_GROUPS_ENUM.HACKER,
    name: "Hack",
    templates: [HACKER_ADDRESS_ALERT_TEMPLATE],
  },
  [ALERT_GROUPS_ENUM.HACK_EVENT]: {
    id: ALERT_GROUPS_ENUM.HACK_EVENT,
    name: "Hack Event",
    templates: [HACK_EVENT_ALERT_TEMPLATE],
  },
  [ALERT_GROUPS_ENUM.FLASHLOAN]: {
    id: ALERT_GROUPS_ENUM.FLASHLOAN,
    name: "Flashloan Alert",
    templates: [FLASHLOAN_ALERT_TEMPLATE],
  },
  [ALERT_GROUPS_ENUM.PRICE_SLIPPAGE]: {
    id: ALERT_GROUPS_ENUM.PRICE_SLIPPAGE,
    name: "Price Slippage Alert",
    templates: [PRICE_SLIPPAGE_ALERT_TEMPLATE],
  },
};

export const HACK_EVENTS_CHECKBOX_FIELDNAMES = {
  ALERT_NAME: "enableAlertName",
  ATTACK_METHOD: "enableAttackMethod",
  NOTE: "enableNote",
};

export const HACK_EVENTS_CHECKBOX_OPTIONS = [
  { label: "Alert Name", fieldName: HACK_EVENTS_CHECKBOX_FIELDNAMES.ALERT_NAME },
  { label: "Attack Method", fieldName: HACK_EVENTS_CHECKBOX_FIELDNAMES.ATTACK_METHOD },
  { label: "Note", fieldName: HACK_EVENTS_CHECKBOX_FIELDNAMES.NOTE },
];

export const PRICE_SLIPPAGE_CHECKBOX_FIELDNAMES = {
  ALERT_NAME: "enableAlertName",
  TOKEN_ADDRESS: "enableTokenAddress",
  PEGGING_POOL: "enablePeggingPool",
};

export const PRICE_SLIPPAGE_CHECKBOX_OPTIONS = [
  { label: "Alert Name", fieldName: PRICE_SLIPPAGE_CHECKBOX_FIELDNAMES.ALERT_NAME },
  { label: "Token Address", fieldName: PRICE_SLIPPAGE_CHECKBOX_FIELDNAMES.TOKEN_ADDRESS },
  { label: "Pegging Pool", fieldName: PRICE_SLIPPAGE_CHECKBOX_FIELDNAMES.PEGGING_POOL },
];

export const FLASHLOAN_CHECKBOX_FIELDNAMES = {
  ALERT_NAME: "enableAlertName",
  PROVIDER: "enableProvider",
  TRANSACTION: "enableTransaction",
};

export const FLASHLOAN_CHECKBOX_OPTIONS = [
  { label: "Alert Name", fieldName: FLASHLOAN_CHECKBOX_FIELDNAMES.ALERT_NAME },
  { label: "Provider", fieldName: FLASHLOAN_CHECKBOX_FIELDNAMES.PROVIDER },
  { label: "Transaction", fieldName: FLASHLOAN_CHECKBOX_FIELDNAMES.TRANSACTION },
];

export const PLATFORM_OPTIONS_WITH_OTHER = [
  {
    label: "All Platforms",
    value: "all",
  },
  {
    label: "BNB Chain",
    value: "bsc",
  },
  {
    label: "ETH",
    value: "eth",
  },
  {
    label: "Polygon",
    value: "polygon",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const PLATFORM_OPTIONS = [
  {
    label: "All Platforms",
    value: "all",
  },
  {
    label: "BNB Chain",
    value: "bsc",
  },
  {
    label: "ETH",
    value: "eth",
  },
  {
    label: "Polygon",
    value: "polygon",
  },
  {
    label: "Fantom",
    value: "ftm",
  },
  {
    label: "Avalanche",
    value: "avax",
  },
];

export const FLASHLOAN_CRITICALITY = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Exploit",
    value: "exploit",
  },
  {
    label: "Non-Exploit",
    value: "nonExploit",
  },
];

export const PRICE_SLIPPAGE_CUSTOMIZE_FIELD_NAMES = {
  PLATFORM: "platform",
  TOKEN_ADDRESS: "tokenAddress",
  PEGGING_POOL: "peggingPool",
  DROPPING_PERCENTAGE: "droppingPercentage",
};

export const PRICE_SLIPPAGE_CUSTOMIZE_FIELD = [
  { label: "Platform", fieldName: PRICE_SLIPPAGE_CUSTOMIZE_FIELD_NAMES.PLATFORM },
  { label: "Token Address", fieldName: PRICE_SLIPPAGE_CUSTOMIZE_FIELD_NAMES.TOKEN_ADDRESS },
  { label: "Pegging Pool", fieldName: PRICE_SLIPPAGE_CUSTOMIZE_FIELD_NAMES.PEGGING_POOL },
  {
    label: "Dropping Percentage (0-100)",
    fieldName: PRICE_SLIPPAGE_CUSTOMIZE_FIELD_NAMES.DROPPING_PERCENTAGE,
  },
];

export const FLASHLOAN_CUSTOMIZE_FIELD_NAMES = {
  PLATFORM: "platform",
  LOAN_AMOUNT: "loanAmount",
  PROFIT: "profit",
  CRITICALITY: "criticality",
  LIKELIHOOD: "likelihood",
};

export const ALERT_QUERY_BOUNDARY_DEFAULT = 4000000000; // 75 years later
