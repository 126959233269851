import React from "react";
import { Skeleton, Divider } from "antd";
import { Title } from "../SharedComponents/Typography";
import { ListOfComment } from "./ListOfComment";
import { useTheme } from "../ReactHooks";
import { useTranslation } from "react-i18next";
import { Header } from "./Header";

export default function ProjectDetailSkeleton({
  hasComments = false,
  style,
  loading = true,
  children,
  showProjectProgressTitle = false,
}) {
  const { t } = useTranslation();
  return loading ? (
    <div style={style} data-testid="project-detail-skeleton">
      {showProjectProgressTitle && (
        <Header level={3} style={{ marginTop: 32, marginBottom: 12 }}>
          {t("audits.projectDetail.projectProgress")}
        </Header>
      )}
      <ListLoadingItem />
      <ListLoadingItem />
      <ListLoadingItem />
      <ListLoadingItem />
      <ListLoadingItem />
      {hasComments && (
        <>
          <Header level={3} style={{ marginTop: 25, marginBottom: 8 }}>
            {t("audits.projectDetail.latestMessages")}
          </Header>
          <ListOfComment commentList={[]} loading={true} skeletonCount={3} />
        </>
      )}
    </div>
  ) : (
    <> {children}</>
  );
}

function ListLoadingItem() {
  const [theme] = useTheme();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "20px 0px 20px 8px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexBasis: `${theme.layout.split ? "300px" : "450px"}`,
          }}
        >
          <Skeleton.Avatar active size={"small"} />
          <Skeleton
            active
            title={{
              style: {
                width: `${theme.layout.split ? "300px" : "450px"}`,
                margin: "0px 0px 0px 50px",
                borderRadius: "0px",
              },
            }}
            paragraph={false}
          />
        </div>
        <Skeleton
          active
          title={{
            style: {
              width: "100px",
              margin: "0px 0px 0px 0px",
              borderRadius: "0px",
            },
          }}
          paragraph={false}
          style={{ flexBasis: "100px" }}
        />
      </div>
      <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
    </>
  );
}
