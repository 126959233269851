import { Row, Col } from "antd";
import styled from "styled-components";
import { SwitchComponent } from "./SwitchComponent";
import { COLORS } from "../../const";
import { Paragraph } from "./Text";
import { Flex } from "./Flex";

const StyledRow = styled(Row)`
  margin-bottom: ${(props) => (props.mb ? props.mb : "8px")};
`;

const StyledRowFlexibleHeight = styled(Row)`
  margin-bottom: ${(props) => (props.mb ? props.mb : "8px")};
`;

const TruncatedParagraph = styled(Paragraph)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FieldLabel = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS["gray-900"]};
`;

/**
 * A form/data display row containing a label and the data/input. Switches between a static data display component
 * and an edit component (e.g. input field) when isEditing is true.
 * @param {Object} props
 * @param {Boolean} props.isEditing
 * @param {String} props.label The field label - will be truncated with ellipses if no space
 * @param {React.ReactNode} props.staticComponent The component to show when not in edit mode
 * @param {React.ReactNode} props.editComponent The component to show when editing
 * @param {Boolean} props.isWide
 * @param {Boolean} props.isTall If the field is more than one line, this will align the label with the top line
 * @param {String} props.marginBottom Custom margin-bottom; defaults to 8px
 */
export function EditableFieldRow({
  isEditing,
  label,
  staticComponent,
  editComponent,
  isWide = false,
  tall = false,
  marginBottom,
}) {
  return (
    <>
      {isWide ? (
        <StyledRow align="middle" justify="start" mb={marginBottom}>
          <Col flex="200px">
            <TruncatedParagraph level={2}>{label}</TruncatedParagraph>
          </Col>
          <Col flex="1 0 200px">
            <SwitchComponent on={editComponent} off={staticComponent} state={isEditing} />
          </Col>
        </StyledRow>
      ) : (
        <StyledRow align="middle" mb={marginBottom}>
          <Col span={7} style={{ paddingRight: "16px", alignSelf: tall ? "start" : "center" }}>
            <TruncatedParagraph level={2}>{label}</TruncatedParagraph>
          </Col>
          <Col span={17}>
            <SwitchComponent on={editComponent} off={staticComponent} state={isEditing} />
          </Col>
        </StyledRow>
      )}
    </>
  );
}

export function EditableFieldRowFlexibleHeight({
  isEditing,
  label,
  staticComponent,
  editComponent,
  marginBottom,
}) {
  return (
    <StyledRowFlexibleHeight align="middle" mb={marginBottom}>
      <Col span={7}>
        <TruncatedParagraph level={2}>{label}</TruncatedParagraph>
      </Col>
      <Col span={17}>
        <SwitchComponent on={editComponent} off={staticComponent} state={isEditing} />
      </Col>
    </StyledRowFlexibleHeight>
  );
}

export function StaticFieldRow({ label, staticComponent, marginBottom, tall }) {
  return (
    <StyledRow align="middle" mb={marginBottom}>
      <Col span={7} style={{ paddingRight: "16px", alignSelf: tall ? "start" : "center" }}>
        <TruncatedParagraph level={2}>{label}</TruncatedParagraph>
      </Col>
      <Col span={17}>{staticComponent}</Col>
    </StyledRow>
  );
}
