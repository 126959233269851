import { datadogLogs } from "@datadog/browser-logs";

const levels = {
  ERROR: "error",
  DEBUG: "debug",
  WARN: "warn",
  INFO: "info",
};

const init = () => {
  // TODO: move consts to const file
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    forwardErrorsToLogs: false,
    sampleRate: 100,
    env: process.env.NEXT_PUBLIC_ENV,
    service: "Certik Client Portal",
  });
};

// rawMessage: string
// context?: k-v object
const log = (rawMessage, context, status) => {
  if (process.env.NEXT_PUBLIC_ENV !== "development") {
    const defaultContext = {
      // user: TODO: get user info
      env: process.env.NEXT_PUBLIC_ENV,
    };
    context = { ...context, ...defaultContext };
    datadogLogs.logger.log(rawMessage, context, status);
  } else {
    console.log(rawMessage, context, status);
  }
};

class Logger {
  static levels = levels;
  static initialized;
  static log = (rawMessage, context = {}, status = Logger.levels.INFO) => {
    if (!Logger.initialized) {
      init();
      Logger.initialized = true;
    }
    log(rawMessage, context, status);
  };
}

export default Logger;
