export const MESSAGE_CATEGORY = {
  SKYHARBOR_PROJECT_INFO_UPDATE: "SKYHARBOR_PROJECT_INFO_UPDATE",
  SKYHARBOR_PROJECT_COMMENT_NEW: "SKYHARBOR_PROJECT_COMMENT_NEW",
  SKYHARBOR_PROJECT_FINDING_COMMENT_NEW: "SKYHARBOR_PROJECT_FINDING_COMMENT_NEW",
  SKYHARBOR_PROJECT_NEW: "SKYHARBOR_PROJECT_NEW",
  SKYHARBOR_PROJECT_DELETE: "SKYHARBOR_PROJECT_DELETE",
  SKYHARBOR_USER_LOGIN: "SKYHARBOR_USER_LOGIN",
  SKYHARBOR_USER_INVITE: "SKYHARBOR_USER_INVITE",
  SKYHARBOR_USER_SIGNUP: "SKYHARBOR_USER_SIGNUP",
  SKYHARBOR_REPORT_SIGNOFF: "SKYHARBOR_REPORT_SIGNOFF",
  SKYHARBOR_JIRA_TICKET_CREATE: "SKYHARBOR_JIRA_TICKET_CREATE",
  SKYHARBOR_JIRA_TICKET_DONE: "SKYHARBOR_JIRA_TICKET_DONE",
  SKYHARBOR_MARKETPLACE_PROJECT_BE_INTERESTED: "SKYHARBOR_MARKETPLACE_PROJECT_BE_INTERESTED",
  SKYHARBOR_SKYNET_PROJECT_NEW: "SKYHARBOR_SKYNET_PROJECT_NEW",
  SKYHARBOR_SKYNET_VMS_COMMENT_SYNC: "SKYHARBOR_SKYNET_VMS_COMMENT_SYNC",
  SKYHARBOR_SKYNET_SIGNAL_STATUS_CHANGE: "SKYHARBOR_SKYNET_SIGNAL_STATUS_CHANGE",
  SKYHARBOR_SHARELINK_CREATE_UPDATE: "SKYHARBOR_SHARELINK_CREATE_UPDATE",
  SKYHARBOR_NOTIFY_REPORT_REVIEW: "SKYHARBOR_NOTIFY_REPORT_REVIEW",
  SKYHARBOR_RESEND_INVITATION: "SKYHARBOR_RESEND_INVITATION",
  SKYHARBOR_KYC_REQUEST: "SKYHARBOR_KYC_REQUEST",
  SKYHARBOR_PDF_DOWNLOAD: "SKYHARBOR_PDF_DOWNLOAD",
  SKYHARBOR_WELCOME_MESSAGE: "SKYHARBOR_WELCOME_MESSAGE",
};

export const NEW_CLIENT_QUOTE_REQUEST_NOTIFICATION_LIST_PROD = [
  "connie.lam@certik.com",
  "jason.jiang@certik.com",
];
export const NEW_CLIENT_QUOTE_REQUEST_NOTIFICATION_LIST_TEST = [
  "ziyi.zhang@certik.com",
  "kun.zhao@certik.com",
  "yuzhi.han@certik.com",
  "chloe.tan@certik.com",
];

export const MARKETPLACE_NOTIFICATION_LIST_PROD = [
  "connie.lam@certik.com",
  "jason.jiang@certik.com",
];

export const MARKETPLACE_NOTIFICATION_LIST_TEST = [
  "ziyi.zhang@certik.com",
  "kun.zhao@certik.com",
  "yuzhi.han@certik.com",
  "chloe.tan@certik.com",
];

// Spy channels
export const SPY_CHANNEL_LIST_PROD = ["connie.lam@certik.com"];
export const SPY_CHANNEL_LIST_TEST = [
  "kun.zhao@certik.com",
  "chloe.tan@certik.com",
  "yuzhi.han@certik.com",
];

export const MESSAGE_SOURCE = {
  SKYHARBOR: "SKYHARBOR",
};

export const MESSAGE_VERSION = {
  V1: "V1",
};

// Keys that nice to have on top level of the record.
export const NOTIFICATION_RECORD_SELECTED_KEYS = ["projectId", "userId", "reportId", "tenantId"];
export const NOTIFICATION_SCHEDULE_CATEGORY = {
  MARKETPLACE: "marketplace",
};

export const MARKETPLACE_NOTIFICATION_EXPONENTIAL_BACKOFF_INTERVAL_SET_IN_DAYS = [2, 4, 8, 15, 30];
export const SIGNUP_NOTIFICATION_BODY = " has accepted invitation.";
export const SIGNUP_NOTIFICATION_TITLE = "Invitation Accepted";
export const AUDIT_PROJECTS_COMMENTS_NOTIFICATION_TITLE = "Comments added";
