import { useEffect, useState, useRef } from "react";
import { Modal } from "antd";
import { useBuildVersion } from "../ReactHooks/useBuildVersion";
import { message } from "../SharedComponents";
import { useStore } from "../ReactHooks";
const BuildVersionMessage = () => {
  const [store] = useStore();
  const [modal, contextHolder] = Modal.useModal();
  let pageFocus = useRef(true);
  const { start, stop, notifyUser } = useBuildVersion();

  // User has switched back to the tab
  const onFocus = () => {
    pageFocus.current = true;
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    pageFocus.current = false;
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  useEffect(() => {
    if (store?.userInfo?.userId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      start(pageFocus);
    }
    return () => {
      stop();
    };
  }, [store?.userInfo?.userId]);

  useEffect(() => {
    if (notifyUser) {
      message.warning("New web version is available, please refresh the page.", 5);
    }
  }, [notifyUser]);

  return <div>{contextHolder}</div>;
};
export default BuildVersionMessage;
