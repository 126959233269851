import React, { useCallback, useMemo, useState } from "react";
import { Col, Row, Table as AntdTable } from "antd";
import styled, { css } from "styled-components";
import { COLORS } from "../../const";
import { Text } from "./Typography";
import { itemRender, useCustomizeSizeChanger } from "./Pagination";

export const StyledTable = styled(({ pagination, onChange, ...props }) => {
  const { tableRef, _pagination, _onChange } = useCustomizations(pagination);
  return (
    <div ref={tableRef}>
      <AntdTable
        pagination={
          pagination === false
            ? false
            : { itemRender: pagination.itemRender || itemRender, ..._pagination }
        }
        onChange={onChange || _onChange}
        {...props}
      />
    </div>
  );
})`
  overflow: auto;

  /* 
  * For some reason the 'overflow: auto' above makes the size changer dropdown shift whenever it opens.
  * For some reason, changing 'display: block' to 'visibility: hidden' down below fixes that.
  */

  .ant-select-dropdown-hidden {
    display: block;
    visibility: hidden;
  }

  /* 
  * Customise the background on-hover color of non-selected rows to a darker gray compared to  
  * default without overriding the background of selected rows 
  */
  && tbody > tr:not(.ant-table-row-selected):hover > td {
    background: var(--gray-100);
  }

  && tbody > tr.ant-table-row-selected:hover > td {
    background: var(--gray-200);
  }

  ${(props) =>
    !props.devMode &&
    css`
      && tbody > tr.ant-table-row-selected > td:first-child::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-right: 8px solid var(--ck2);
        background: var(--gray-200);
        height: calc(100% + 1px);
        opacity: 1;
        content: "";
      }

      && tbody > tr.ant-table-expanded-row > td:first-child::before {
        background: var(--gray-200);
      }
    `}

  /* 
  * Customise the background color of cells in a selected sorting column
  */
  && tbody > tr > td.ant-table-column-sort {
    background: ${COLORS["gray-3"]};
  }

  /*
  * Default font styles for table head and body cells 
  */
  && .ant-table-thead > tr > .ant-table-cell {
    padding: ${(props) => (props.size === "small" ? "3px 10px 3px 8px" : "4px 10px 4px 24px")};
    font-size: 13px;
    font-weight: 600;
    color: ${COLORS["gray-600"]};
    min-height: ${(props) => (props.size === "small" ? "20px" : "30px")};
    border-top: 1px ${COLORS["gray-300"]} solid;
    border-bottom: 1px ${COLORS["gray-300"]} solid;
    background: ${(props) =>
      props.isGray50Bg === true ? COLORS["gray-50"] : props?.backgroundColor || "white"};
  }

  && .ant-table-tbody > tr > .ant-table-cell {
    height: ${(props) => (props.size === "small" ? "36px" : "57px")};
    padding: ${(props) => (props.size === "small" ? "4px 10px 4px 8px" : "8px 10px 8px 24px")};
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: ${COLORS["gray-900"]};
    vertical-align: top;
    background: ${(props) =>
      props.isGray50Bg === true ? COLORS["gray-50"] : props?.backgroundColor || "white"};
  }
  && .ant-table-tbody > tr.ant-table-expanded-row > .ant-table-cell {
    background: ${COLORS["gray-100"]};
  }
  /*
  * Default font styles for table body cells when crowded
  */
  && tbody > tr.ant-table-row-crowded > td.ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
    line-height: 14px;
  }

  && tbody > tr.ant-table-row-very-crowded > td.ant-table-cell {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
    line-height: 14px;
  }

  /* 
  * Table header dividers hidden
  */
  &&
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: none;
  }
`;

const CenterAlignedCol = styled(Col)`
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
`;

/**
 * Our Table component is a styled wrapper of antd's Table component.
 *
 * @param {{ pageSize: number, current: number }} [props.pagination] Default values: {pageSize: 10, current: 1}
 * @param {function} [props.onChange] Must define onChange if you if you provide pageSize/current and want it to update. Otherwise, the values will stay static.
 *
 * Please refer to https://ant.design/components/table/#API for details of Table API.
 */

export function Table({ pagination, onChange, onPageChange, ...props }) {
  const { tableRef, _pagination, _onChange } = useCustomizations(pagination, onPageChange);
  return (
    <div className="table-container" ref={tableRef}>
      <StyledTable
        pagination={pagination === false ? false : { itemRender, ..._pagination }}
        onChange={onChange || _onChange}
        {...props}
      />
    </div>
  );
}

/**
 * Hook used to set the pagination, onChange, and tableRef.
 * Sets default values too.
 * Makes use of useCustomSizeChanger to customize size changer dropdown.
 */
function useCustomizations(pagination, onPageChange) {
  // Default props:
  const [_current, _setCurrent] = useState(pagination?.current || 1);
  const [_pageSize, _setPageSize] = useState(pagination?.pageSize || 10);
  const _pagination = useMemo(
    () => ({
      pageSize: pagination?.pageSize || _pageSize,
      current: pagination?.current || _current,
      ...(pagination?.showSizeChanger !== undefined && {
        // if a custom showSizeChanger value is provided, use that
        showSizeChanger: pagination.showSizeChanger,
      }),
    }),
    [pagination, _pageSize, _current]
  );

  const _onChange = (newPagination) => {
    _setCurrent(newPagination.current);
    _setPageSize(newPagination.pageSize);
    onPageChange(newPagination.current, newPagination.pageSize);
  };

  // Callback ref to replace icons & text
  const tableRef = useCustomizeSizeChanger([_pagination?.pageSize]);

  return {
    _pagination,
    _onChange,
    tableRef,
  };
}

/**
 * SkyharborTableColumnHeader component - Utilize this component in each title field of columns for tables.
 * @param {Object} props props to pass in
 * @param {string | React.ReactNode | undefined} props.text Text of the column header
 * @param {string | React.ReactNode | undefined} props.prefixComponent Component to render to the left of the text
 * @param {string | React.ReactNode | undefined} props.suffixComponent Component to render to the right of the text
 * @param {Object | undefined} props.prefixComponent Props to override props of inner Row parent wrapper
 * @example
 * ```jsx
 * <SkyharborTableColumnHeader text="Last Location" />
 * <SkyharborTableColumnHeader
 *    text={t("roles")}
 *    suffixComponent={
 *     <Tooltip title={t("rolesTip")}>
 *       <InfoCircleOutlined style={{ marginLeft: "4px" }} />
 *     </Tooltip>
 *    }
 * />
 * ```
 */

const StyledRow = styled(Row)`
  min-height: ${(props) => (props.size === "small" ? "20px" : "30px")};
`;

export function SkyharborTableColumnHeader({
  text,
  prefixComponent,
  suffixComponent,
  style,
  rowProps = {},
}) {
  return (
    <StyledRow gutter={2} wrap={false} style={style} {...rowProps}>
      {prefixComponent ? <CenterAlignedCol>{prefixComponent}</CenterAlignedCol> : <></>}
      {text ? (
        <CenterAlignedCol>
          <TableColumnHeaderText text={text} />
        </CenterAlignedCol>
      ) : (
        <></>
      )}
      {suffixComponent ? <CenterAlignedCol>{suffixComponent}</CenterAlignedCol> : <></>}
    </StyledRow>
  );
}

/**
 * TableColumnHeaderText component - Utilize this component when customizing own ColumnHeader Component.
 * @param {Object} props props to pass in
 * @param {string | React.ReactNode | undefined} props.text Text of the column header
 * @example
 * ```jsx
 * <TableColumnHeaderText text="Project Name" />
 * <TableColumnHeaderText text={<SomeComponent />} />
 * ```
 */
export function TableColumnHeaderText({ text }) {
  return (
    <Text color={COLORS["gray-600"]} size={13} lineHeight={20} weight={600}>
      {text}
    </Text>
  );
}

/**
 * TableCellText component - Utilize this component when writing text into the render function of a cell.
 * @param {Object} props props to pass in
 * @param {string | React.ReactNode | undefined} props.text Text of the cell item
 * @example
 * ```jsx
 * <TableCellText text="John Smith" />
 * <TableCellText text={<SomeComponent />} />
 * ```
 */
export function TableCellText({ text, justify = "start", fontWeight = 400 }) {
  return (
    <Text
      color={COLORS["gray-ext2"]}
      size={14}
      lineHeight={20}
      weight={fontWeight}
      style={{ letterSpacing: "-0.2px", display: "flex", justifyContent: justify }}
    >
      {text}
    </Text>
  );
}
