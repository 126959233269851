import { Spin } from "antd";
import { cls } from "../../utils/common/cls";
import styles from "./spinner.module.css";
import React from "react";

export const DrawerSpinner = (spinning) => (
  <Spin
    style={{
      position: "relative",
      marginLeft: "45%",
      marginTop: "25%",
    }}
    size="large"
    spinning={spinning}
  ></Spin>
);

export const PageSpinner = (spinning) => (
  <div
    style={{
      position: "relative",
      width: "100%",
      height: "80vh",
    }}
  >
    <Spin
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      size="large"
      spinning={spinning}
      indicator={<img src="/page_loader.gif" style={{ width: "96px", height: "96px" }} />}
    ></Spin>
  </div>
);

export const BlockSpinner = (
  <div className={cls(styles, ["cube-loading-grid"])}>
    <div className={cls(styles, ["cube-loading", "cube-loading1"])}></div>
    <div className={cls(styles, ["cube-loading", "cube-loading2"])}></div>
    <div className={cls(styles, ["cube-loading", "cube-loading3"])}></div>
    <div className={cls(styles, ["cube-loading", "cube-loading4"])}></div>
    <div className={cls(styles, ["cube-loading", "cube-loading5"])}></div>
    <div className={cls(styles, ["cube-loading", "cube-loading6"])}></div>
    <div className={cls(styles, ["cube-loading", "cube-loading7"])}></div>
    <div className={cls(styles, ["cube-loading", "cube-loading8"])}></div>
    <div className={cls(styles, ["cube-loading", "cube-loading9"])}></div>
  </div>
);
