import { Modal as AntdModal } from "antd";
import styled from "styled-components";

const StyledModal = styled(AntdModal)`
  top: 40px;
`;

export function Modal({
  children,
  title,
  footer,
  visible,
  submitting,
  onCancel,
  bodyStyle = {},
  width = "calc(100vw - 80px)",
  ...props
}) {
  return (
    <StyledModal
      title={title || null}
      footer={footer || null}
      visible={visible}
      confirmLoading={submitting}
      onCancel={onCancel}
      width={width}
      bodyStyle={{
        maxWidth: "586px",
        minHeight: "calc(100vh - 80px - 53px)",
        margin: "auto",
        ...bodyStyle,
      }}
      {...props}
    >
      {children}
    </StyledModal>
  );
}
