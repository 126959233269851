import { Button } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { COLORS } from "../../const";
import React from "react";

const StyledButton = styled(Button)`
  border-color: ${COLORS["gray-300"]};
  border-width: 1px;
  box-shadow: none;
  -webkit-box-shadow: none;
  height: 32px;
  width: 32px;
  border-radius: 4px;

  &[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  &:hover,
  &:focus,
  &:active {
    background: ${COLORS["gray-3"]};
    border-color: ${COLORS["CK2"]};
  }
`;

export function IconButton({ icon, onClick, disabled, href, loading, testid }) {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      href={href}
      data-testid={testid}
      loading={loading}
      type="default"
      icon={icon}
    />
  );
}

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  loading: PropTypes.bool,
};
