export const JIRA_TICKET_TAG = {
  SKYHARBOR: "SkyHarbor",
  DEPRECATED: "Deprecated",
};

export const JIRA_TICKET_ACTION_TYPE = {
  PUBLISH: "publish",
  UNPUBLISH: "unpublish",
};

export const JIRA_TICKET_TITLE_TYPE = {
  UPLOAD: "upload the report",
  UNPUBLISH: "unpublish the report",
  REMEDIATION_ACTION: "skynet signal remediation",
  UPDATE_PROJECT_NAME: "update project name",
};

export const JIRA_TICKET_ACTION_TITLE_MAP = {
  [JIRA_TICKET_ACTION_TYPE.PUBLISH]: JIRA_TICKET_TITLE_TYPE.UPLOAD,
  [JIRA_TICKET_ACTION_TYPE.UNPUBLISH]: JIRA_TICKET_TITLE_TYPE.UNPUBLISH,
};

export const JIRA_TICKET_STATUS = {
  DONE: "Done",
};
