import { Drawer as AntdDrawer } from "antd";
import { useTheme } from "../ReactHooks";
import styled from "styled-components";
import { DrawerTitle } from "./DrawerTitle";
import { COLORS } from "../../const/colors";

const StyledDrawer = styled(AntdDrawer)`
  .ant-drawer-content-wrapper .ant-drawer-close {
    padding-top: 30px;
    padding-right: 35px;
  }
  .ant-drawer-content-wrapper .ant-drawer-close svg {
    height: 18px;
    width: 18px;
    color: ${COLORS["deepBlue"]};
  }
  .ant-drawer-body .ant-tabs-nav-list {
    width: initial;
  }
  .ant-drawer-body {
    padding: 0px;
  }
`;

/**
 * @param {boolean} visible The drawer is visible or not
 * @param {number} width The width of drawer
 * @param {number} title The title of drawer
 * @param {ReactNode} children The content of the drawer
 * @param {Function} onClose The callback function when drawer close
 * @param {boolean} forceDrawerOverlay Set to true to override user's theme layout preferences and use the drawer render format only
 */
export function Drawer({ visible, width, title, children, onClose, forceDrawerOverlay = false }) {
  const [theme] = useTheme();
  return theme.layout.split && !forceDrawerOverlay ? (
    children
  ) : (
    <StyledDrawer
      data-testid="drawer"
      placement="right"
      visible={visible}
      closable={false}
      maskClosable={true}
      closeIcon={null}
      key="right"
      width={width}
      onClose={onClose}
      destroyOnClose
    >
      {title && <DrawerTitle name={title} />}
      {children}
    </StyledDrawer>
  );
}
