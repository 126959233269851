import React from "react";
import styled from "styled-components";
import { COLORS } from "../../const/colors";

const paragraphStyles = {
  [1]: {
    fontSize: "16px",
    lineHeight: "24px",
    weight: 400,
    color: "gray-900",
    marginBottom: 0,
  },
  [2]: {
    fontSize: "13px",
    lineHeight: "20px",
    weight: 400,
    color: "gray-900",
    marginBottom: 0,
  },
  [3]: {
    fontSize: "12px",
    lineHeight: "16px",
    weight: 400,
    color: "gray-900",
    marginBottom: 0,
  },
};

export const StyledParagraph = styled.p`
  font-style: normal;
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  margin-bottom: ${(props) => props.marginBottom};
  display: ${(props) => (props.ellipsis ? "block" : "inherit")};
  white-space: ${(props) => (props.ellipsis ? "nowrap" : "normal")};
  overflow: ${(props) => (props.ellipsis ? "hidden" : "auto")};
  text-overflow: ${(props) => (props.ellipsis ? "ellipsis" : "initial")};
`;

/**
 * The Paragraph component is used to display general text in the application.
 * The Paragraph component should take a `level` prop (1-4) to determine the class of paragraph.
 * The default level is 1.
 * Here are some examples of how to use the Paragraph component:
 * ```jsx
 * <Paragraph level={1}>
 *    This is a generic text
 * </Paragraph>
 * ```
 * ```jsx
 * <Paragraph level={1} med>
 *    This is a bolded text
 * </Paragraph>
 * ```
 * ```jsx
 * <Paragraph level={2}>
 *     this is the most common type of text.
 * </Paragraph>
 * ```
 * ```jsx
 * <Paragraph level={2} med>
 *     This is a strong version of some general text.
 * </Paragraph>
 * ```
 * ```jsx
 * <Paragraph level={3}>
 *     This is for footnotes or other small text.
 * </Paragraph>
 * ```
 * @param children
 * @param {Object} props
 * @param {number} props.level Level for the text (1-3). corresponds with P16r, P14r, and N.
 * @param {boolean} props.med this toggles the font weight to 500, else it's 400.
 * @param {string} props.fontSize This will override the font size.
 * @param {string} props.lineHeight This will override the line height.
 * @param {string} props.weight This will override the font weight.
 * @param {string} props.as this is override the tag type... default is p, but can be something like span, label, etc.
 * @param {string} props.marginBottom This will override the margin bottom.
 * @param {string} props.color This will override the color.
 * @param {boolean} ellipsis Show ellipsis symbol if the text overflow
 * @returns {JSX.Element} element
 */
export function Paragraph({
  level = 1,
  fontSize,
  lineHeight,
  weight,
  children,
  as = "p",
  med = false,
  marginBottom,
  color,
  ellipsis = false,
  ...props
}) {
  if (level > 3 || level < 1) {
    console.error("Paragraph level must be between 1 and 3; defaulting to 1");
    level = 1;
  }
  const {
    fontSize: defaultFontSize,
    lineHeight: defaultLineHeight,
    weight: defaultWeight,
    color: defaultColor,
    marginBottom: defaultMarginBottom,
  } = paragraphStyles[level];

  return (
    <StyledParagraph
      as={as}
      size={fontSize || defaultFontSize}
      lineHeight={lineHeight || defaultLineHeight}
      weight={weight || (med ? 500 : defaultWeight)}
      color={COLORS[color] || color || COLORS[defaultColor]}
      marginBottom={marginBottom || defaultMarginBottom}
      ellipsis={ellipsis}
      {...props}
    >
      {children}
    </StyledParagraph>
  );
}
