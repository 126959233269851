import styled from "styled-components";

export const Flex = styled.div.attrs((props) => {
  return {
    justifyContent: props.justifyContent || "flex-start",
    flexDirection: props.flexDirection || "row",
    flexGrow: props.flexGrow || 0,
    flexBasis: props.flexBasis || "auto",
    flexShrink: props.flexShrink || 1,
    flexWrap: props.flexWrap || "nowrap",
    flex: props.flex || "0 1 auto",
    alignItems: props.alignItems || "stretch",
    margin: props.margin || "0",
    padding: props.padding || "0",
    width: props.width || "auto",
  };
})`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  flex-grow: ${(props) => props.flexGrow};
  flex-basis: ${(props) => props.flexBasis};
  flex-shrink: ${(props) => props.flexShrink};
  flex-wrap: ${(props) => props.flexWrap};
  flex: ${(props) => props.flex};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
`;
