import { useState, useEffect } from "react";
import { useRouter } from "next/router";

const DEV_MODE_KEY = "debugger";

export function useDevMode() {
  const router = useRouter();
  const [devMode, setDevMode] = useState((router.query.mode || "") === DEV_MODE_KEY);

  useEffect(() => {
    setDevMode((router.query.mode || "") === DEV_MODE_KEY);
  }, [router.query.mode]);

  return devMode;
}
